import React, { useState } from "react";
import Viewer from "react-viewer";
const ReceiptViewer = ({ url }) => {
  const isImage = url.includes(".jpg");
  return (
    <div className="receipt-visual-obj" id="image-container">
      {isImage ? (
        <ImageViewer url={url} />
      ) : (
        <object data={url}>PDF Viewer</object>
      )}
    </div>
  );
};

const ImageViewer = ({ url }) => {
  const [visible, setVisible] = useState(false);
  return !visible ? (
    <object data={url} onClick={() => setVisible(!visible)}>
      Image Viewer
    </object>
  ) : (
    <Viewer
      className="image-viewer"
      container={document.querySelector("#image-container")}
      visible={visible}
      scalable={false}
      noNavbar={true}
      noImgDetails={true}
      zoomSpeed={0.2}
      defaultScale={1.2}
      onClose={() => setVisible(!visible)}
      images={[{ src: url, alt: "" }]}
    />
  );
};
export default ReceiptViewer;
