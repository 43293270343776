import React, { memo } from "react";
// import { humanizeCurrency } from "../../common/utils";
import { humanizeCurrency } from "../../utils"
import default_merchant_logo from "../../logos/default-merchant-logo.jpg";

const TopMerchants = memo(({ topMerchants }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Store name</th>
          <th>Category</th>
          <th>ITEMS SOLD</th>
          <th>AVG. BASKET SIZE</th>
          <th>Revenue</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {topMerchants &&
          topMerchants.map(
            ({
              store_name,
              category,
              items_sold,
              average_bucket_size,
              revenue,
              logo
            }, i) => (
              <tr key={store_name || i}>
                <td>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div>
                        <span className="merchant-img center-align">
                          <img src={logo || default_merchant_logo} alt="!" />
                        </span>
                      </div>
                    </div>
                    <div className="center-align">
                      <div>
                        <span>{store_name}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{category || "N/A"}</td>
                <td>
                  {items_sold
                    ? humanizeCurrency({
                        amount: items_sold,
                        type: "number"
                      })
                    : "N/A"}
                </td>
                <td>
                  {average_bucket_size
                    ? humanizeCurrency({
                        amount: average_bucket_size,
                        type: "number"
                      })
                    : "N/A"}
                </td>
                <td>
                  {humanizeCurrency({
                    amount: revenue,
                    type: "currency"
                  })}
                </td>
                {/* <td><a href="" className="action"><i className="icon-more"></i></a></td> */}
              </tr>
            )
          )}
      </tbody>
    </table>
  );
});

export default TopMerchants;
