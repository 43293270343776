import { isEmpty } from "lodash";
import { humanizeCurrency } from "../../../utils";

const calculateSalesReportData = entry => {
  const categories = [];
  const values = [];
  const months = [];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  for (let key in entry) {
    if (key !== "month") {
      values.push(entry[key]);
      categories.push(key);
      months.push(monthNames[entry.month - 1].substring(0, 3));
    }
  }
  return [
    ...categories.map((category, i) => [
      months[i],
      category,
      "",
      "",
      humanizeCurrency({ amount: values[i], type: "number" })
    ])
  ];
};
export const getSalesReportData = salesReport => {
  let salesReportData = [];
  if (!isEmpty(salesReport.data)) {
    salesReportData = [
      {
        columns: ["Month", "Category", "", "", "Value"],
        data: []
      }
    ];
    salesReport.data.forEach(entry => {
      salesReportData.push({
        columns: ["", "", "", "", ""],
        data: calculateSalesReportData(entry)
      });
    });
  }
  return salesReportData;
};
