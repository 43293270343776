import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { omit } from "lodash";

import MerchantList from "./MerchantList";
// import BaseLoader from "../../components/BaseLoader";
import EmaarLoader from "../../components/EmaarLoader";
import { fetchAllCategories } from "../../actions/merchants/";

const HomeContainer = props => {
  const { dispatch, isDataAvailable, isLoading } = props;
  const styles = {
    width: "100%",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  // =================================================
  useEffect(() => {
    if (!isLoading && !isDataAvailable) {
      dispatch(fetchAllCategories());
    }
  }, []);
  // =================================================
  return (
    <>
      {isLoading && (
        <div style={styles}>
          <EmaarLoader height={80} width={80} />
          {/* <BaseLoader /> */}
        </div>
      )}
      {!isLoading && isDataAvailable && (
        <MerchantList
          {...omit(props, ["isLoading", "isDataAvailable", "data"])}
        />
      )}
    </>
  );
};
// =================================================
const mapStateToProps = state => ({ ...state.merchants.categories });
// =================================================

export default memo(connect(mapStateToProps)(HomeContainer));
