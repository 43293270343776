import React from "react";
import { CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar } from "recharts";
import {
  formatDate,
  tooltipFormatter,
  BarChartLabelFormatter
} from "../../utils";
const index = ({ chart, xAxisDataKey, dataKey }) => {
  return (
    <BarChart width={400} height={250} data={chart} barGap={1}>
      <CartesianGrid vertical={false} />
      <XAxis dataKey={xAxisDataKey} tickFormatter={formatDate} />
      <YAxis />
      <Tooltip
        labelFormatter={tooltipFormatter}
        formatter={BarChartLabelFormatter}
        cursor={{
          fill: "none"
        }}
        itemStyle={{
          textTransform: "capitalize"
        }}
        contentStyle={{
          textTransform: "capitalize",
          fontSize: "9px",
          padding: "4px",
          borderRadius: "5px"
        }}
      />
      {/* <Legend verticalAlign="top" align="right" height={36}/> */}
      <Bar dataKey={dataKey} fill="#1267fb" />
    </BarChart>
  );
};

export default index;
