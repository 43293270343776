import React, { useState, memo } from "react";

export default memo(({ store_name, handleSearchChange }) => {
  const [storeName, setStoreName] = useState(store_name);
  const onSearchTerm = e => {
    setStoreName(e.target.value);
    setTimeout(handleSearchChange(e.target.value), 2000);
  };
  return (
    <form className="search-form" onSubmit={e => e.preventDefault()}>
      <input
        type="search"
        value={storeName}
        placeholder="Search by store name"
        onChange={onSearchTerm}
      />
    </form>
  );
});
