import React, { useState } from "react";
import "./baseContainer.scss";
import createrlogo from "../../images/creater-logo.png";
import logo from "../../images/logo.png";
import icon from "../../images/img.png";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/user";
// ======================================================
const BaseContainer = ({
  logout,
  location: { pathname },
  user,
  history: { push },
  children
}) => {
  // ======================================================
  const [showDropdown, setShowDropdown] = useState(false);
  const handleLogout = e => {
    e.preventDefault();
    localStorage.removeItem("user");
    logout();
    push("/login");
  };
  const { firstName = "", lastName = "" } = user || {};
  const isHome = pathname === "/";
  const isMerchants = pathname === "/merchants";
  // const isCustomers = pathname === "/customers";
  const isReceipts = pathname === "/receipts";
  // const isSettings = pathname === "/settings";
  const isSupport = pathname === "/support";
  // ======================================================
  return (
    <div className="Wrapper">
      <div className="sidebar">
        <a
          href="#/"
          className="sidebar-close"
          onClick={() => document.body.classList.remove("sidebar-active")}
        >
          <i className="icon-close"></i>
        </a>
        <div className="logo">
          <Link to="/">
            <img src={logo} className="customer-img" alt="des" />
          </Link>
        </div>
        <div className="nav-holder">
          <span className="title">Main</span>
          <ul className="nav">
            <li className={isHome ? "active" : ""}>
              <Link to={"/"}>
                <i className="icon-dashbord"></i> Dashboard
              </Link>
            </li>

            <li className={isMerchants && "active"}>
              <Link to={"/merchants"}>
                <i className="icon-merchant"></i> Merchants
              </Link>
            </li>
            <li className={isReceipts && "active"}>
              <Link to={"/receipts"}>
                <i className="icon-merchant"></i> Receipts
              </Link>
            </li>

            {/* <li className={isCustomers && "active"}><Link to={'/customers'}><i className="icon-customer"></i> Customers</Link></li> */}
            {/* <li className={isReceipts && "active"}><Link to={'/receipts'}><i className="icon-recipts"></i> Receipts</Link></li> */}
          </ul>
          <span className="title">help</span>
          <ul className="nav">
            {/* <li><a href="#/"><i className="icon-setting"></i> Settings <span className="number">3</span></a></li> */}
            <li className={isSupport ? "active" : ""}>
              <Link to="/support">
                <i className="icon-phone"></i> Support
              </Link>
            </li>
          </ul>
        </div>
        <div className="craeter-area">
          <div className="creater-logo">
            <img src={createrlogo} className="customer-img" alt="des" />
          </div>
          <span className="copyright">
            © 2019 VisionX Technologies, Inc. All rights reserved.
          </span>
        </div>
      </div>
      {/* add className="user-active" in id="header" div */}
      <header id="header" className={showDropdown ? "user-active" : ""}>
        <div
          href="#"
          className="sidebar-opener"
          onClick={() => document.body.classList.add("sidebar-active")}
        ></div>
        {/* <a href="#/" className="back-page">Back to Merchants</a> */}
        <div className="header-right">
          <div className="header-notification">
            {/* <a href="#/" className="icon-notification alert"></a> */}
          </div>
          <div
            className="user-info"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className="user-holder">
              <img src={icon} width="20" height="20" alt="des" />
              <div className="info">
                <span className="name">{`${firstName} ${lastName}`}</span>
                {/* <span className="name">{`First Last`}</span> */}
                <span className="admin">User</span>
              </div>
            </div>
            <div className="user-dropdown">
              <ul className="user-links">
                {/* <li><a href="#/">Profile</a></li> */}
                <li>
                  <a href="#/" onClick={handleLogout}>
                    {" "}
                    Logout
                  </a>
                </li>
              </ul>
            </div>
            <span className="overlay"></span>
          </div>
        </div>
      </header>
      <main id="main">{children}</main>
    </div>
  );
};
// ======================================================
const mapStateToProps = state => ({
  user: state.user.user
});
// ======================================================
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});
// ======================================================
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseContainer)
);
