import React from "react";
import "./support.scss";
import icon from "../../images/icon-success.png";

import { connect } from "react-redux";
import { sendSupportQuery } from "../../actions/support";

import Loader from "../../components/SpiralLoader";
// =================================================================

class Support extends React.Component {
  state = {
    name: `${this.props.user.firstName} ${this.props.user.lastName}`,
    email: this.props.user.email,
    subject: "",
    message: "",
    showSuccessMessage: false
  };
  // =================================================================

  componentWillReceiveProps(nextProps) {
    const {
      support: { isSupportQuerySent }
    } = this.props;
    if (!isSupportQuerySent && nextProps.support.isSupportQuerySent) {
      this.toggleSuccessMessage();
    }
  }

  // =================================================================

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  // =================================================================

  handleSendMessage = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { name, email, subject, message } = this.state;
    dispatch(sendSupportQuery({ name, email, subject, message }));
  };

  // =================================================================

  handleSendAnother = () => {
    this.setState(
      {
        subject: "",
        message: ""
      },
      () => this.toggleSuccessMessage()
    );
  };

  // =================================================================

  toggleSuccessMessage = () => {
    if (this.props.support.isSupportQuerySent) {
      this.setState({
        showSuccessMessage: false
      });
    } else {
      this.setState({
        showSuccessMessage: true
      });
    }
  };

  // =================================================================

  render() {
    const { name, email, subject, message, showSuccessMessage } = this.state;
    const {
      support: { isSendingSupportQuery }
    } = this.props;
    console.log("======================");
    console.log(this.state.showSuccessMessage);
    console.log("======================");
    return (
      <div className="support-page">
        <h1>Support</h1>
        <p>
          Fill out the form below and our support team will be with you as soon
          as possible.
        </p>
        <div className="support-form-area">
          {/* show and hidde this div when message sent */}
          {showSuccessMessage && (
            <div className="success-message">
              <img src={icon} className="customer-img" alt="des" />
              <h1>Message Sent</h1>
              <p>
                We have received your message and will get back to you shortly.{" "}
                <a href="#/" onClick={this.handleSendAnother}>
                  {!isSendingSupportQuery ? (
                    "Send another"
                  ) : (
                    <Loader height="22" width="100" />
                  )}
                </a>
              </p>
            </div>
          )}

          <h2>New Support Message</h2>
          <form action="#" className="form">
            <div className="form-cols">
              <div className="col">
                <label>Name</label>
                <div className="input-holder user">
                  <input
                    type="text"
                    onChange={this.handleInputChange}
                    name="name"
                    value={name}
                    placeholder="Your Name"
                  ></input>
                </div>
              </div>
              <div className="col">
                <label>Email</label>
                <div className="input-holder mail">
                  <input
                    type="email"
                    onChange={this.handleInputChange}
                    name="email"
                    value={email}
                    placeholder="Your  email address"
                  ></input>
                </div>
              </div>
              <div className="col">
                <label>Subject</label>
                <div className="input-holder subject">
                  <input
                    type="text"
                    onChange={this.handleInputChange}
                    name="subject"
                    value={subject}
                    placeholder="Enter subject"
                  ></input>
                </div>
              </div>
            </div>
            <textarea
              onChange={this.handleInputChange}
              name="message"
              value={message}
              placeholder="Type your message here"
            ></textarea>
            <div className="form-footer">
              <button onClick={this.handleSendMessage} className="btn">
                {!isSendingSupportQuery ? (
                  "Send Message"
                ) : (
                  <Loader height="22" width="100" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
// =================================================================

const mapStateToProps = state => ({
  user: state.user.user,
  support: state.support
});
export default connect(mapStateToProps)(Support);
