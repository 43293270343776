import React from "react";
import { humanizeCurrency } from "../../utils";
import MerchantCategoriesTags from "../MerchantCategoriesTags";
import default_merchant_logo from "../../logos/default-merchant-logo.jpg";
const TableBody = ({ rowData, onMerchantClick }) => (
  <>
    {rowData.map((store, j) => (
      <tr key={j} onClick={() => onMerchantClick(store)}>
        <td>
          <div style={{ display: "flex" }}>
            <div>
              <div>
                <span className="merchant-img center-align">
                  <img src={store.logo || default_merchant_logo} alt="!" />
                </span>
              </div>
            </div>
            <div className="center-align">
              <div>
                <span>{store && store.store}</span>
              </div>
            </div>
          </div>
        </td>
        <td>
          <MerchantCategoriesTags categories={store.categories} />
        </td>
        <td>{store.items_sold}</td>
        <td>
          {humanizeCurrency({
            amount: store.average_basket_size,
            type: "number"
          })}
        </td>
        <td>
          {humanizeCurrency({
            amount: store.revenue,
            type: "currency"
          })}
        </td>
      </tr>
    ))}
  </>
);

export default TableBody;
