// export const SET_MERCHANT_LIST_FROM_DATE = "SET_MERCHANT_LIST_FROM_DATE";
// export const SET_MERCHANT_LIST_TO_DATE = "SET_MERCHANT_LIST_TO_DATE";
export const SET_MERCHANT_LIST_MULTIPLE_CATEGORIES =
  "SET_MERCHANT_LIST_MULTIPLE_CATEGORIES";
// export const SET_MERCHANT_LIST_DATERANGE = "SET_MERCHANT_LIST_DATERANGE";
export const SET_MERCHANT_LIST_FILTERS = "SET_MERCHANT_LIST_FILTERS";
export const SET_LIMIT = "SET_LIMIT";
export const SET_OFFSET = "SET_OFFSET";
export const SET_STORE = "SET_STORE";
export const RESET_FILTERS = "RESET_FILTERS";
export const TOGGLE_MERCHANT_LIST_FILTER_VISIBILITY =
  "TOGGLE_MERCHANT_LIST_FILTER_VISIBILITY";
export const TOGGLE_MERCHANT_LIST_CATEGORY = "TOGGLE_MERCHANT_LIST_CATEGORY";
export const TOGGLE_MERCHANT_LIST_CATEGORIES_ALL =
  "TOGGLE_MERCHANT_LIST_CATEGORIES_ALL";
export const SORT_MERCHANT_LIST_BY = "SORT_MERCHANT_LIST_BY";
export const SORT_MERCHANT_LIST_ORDER = "SORT_MERCHANT_LIST_ORDER";
// =================================================================
// export const setMerchantListFromDate = fromDate => ({
//   type: SET_MERCHANT_LIST_FROM_DATE,
//   payload: fromDate
// });

// export const setMerchantListToDate = toDate => ({
//   type: SET_MERCHANT_LIST_TO_DATE,
//   payload: toDate
// });

// export const setMerchantListDateRange = (fromDate, toDate) => ({
//   type: SET_MERCHANT_LIST_DATERANGE,
//   payload: { fromDate, toDate }
// });

export const setMerchantListFilters = filters => ({
  type: SET_MERCHANT_LIST_FILTERS,
  payload: filters
});

export const resetFilters = filters => ({
  type: RESET_FILTERS,
  payload: filters
});

export const setLimit = limit => ({
  type: SET_LIMIT,
  payload: limit
});

export const setOffset = offset => ({
  type: SET_OFFSET,
  payload: offset
});

export const setStore = store_name => ({
  type: SET_STORE,
  payload: store_name
});

export const toggleMerchantListCategoriesAll = payload => ({
  type: TOGGLE_MERCHANT_LIST_CATEGORIES_ALL,
  payload
});

export const toggleMerchantListFilterVisibility = () => ({
  type: TOGGLE_MERCHANT_LIST_FILTER_VISIBILITY
});

export const toggleMerchantListCategory = payload => ({
  type: TOGGLE_MERCHANT_LIST_CATEGORY,
  payload
});

export const setMerchantListSortBy = sortBy => ({
  type: SORT_MERCHANT_LIST_BY,
  payload: sortBy
});
export const setMerchantListSortOrder = sortOrder => ({
  type: SORT_MERCHANT_LIST_ORDER,
  payload: sortOrder
});
