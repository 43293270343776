import moment from "moment";

// ====================================================================
function find_previous_dates(start_datetime, end_datetime) {
  const diffInDays = Math.abs(
    moment(start_datetime).diff(moment(end_datetime), "days")
  );

  return {
    start_datetime: moment(start_datetime)
      .subtract(diffInDays + 1, "days")
      .format("YYYY-MM-DD"),
    end_datetime: moment(end_datetime)
      .subtract(diffInDays + 1, "days")
      .format("YYYY-MM-DD")
  };
}
// ====================================================================

function find_date_diff(start_datetime, end_datetime) {
  const start_date = moment(start_datetime, "YYYY-MM-DD");
  const end_date = moment(end_datetime, "YYYY-MM-DD");

  const diffInDays = end_date.diff(start_date, "days");
  return diffInDays;
}
// ====================================================================

function findInPrevious(day, data) {
  const { previous = [] } = data;
  const item = previous.filter(item => item.day === day);
  return item.length > 0 ? item[0].sales_figure : 0;
}
// ====================================================================

function findInCurrent(day, data) {
  const { current = [] } = data;
  const item = current.filter(item => item.day === day);
  return item.length > 0 ? item[0].sales_figure : 0;
}
// ====================================================================

export const getTransformedSalesFiguresData = (
  startDate,
  endDate,
  chartData
) => {
  const data = chartData;
  const previousDates = find_previous_dates(
    moment(startDate, "YYYY-MM-DD"),
    moment(endDate, "YYYY-MM-DD")
  );
  const diff = find_date_diff(startDate, endDate);

  const transformedChartData = [];
  if (
    findInCurrent(startDate, data) ||
    findInPrevious(previousDates.start_datetime, data)
  ) {
    transformedChartData.push({
      date: moment(startDate).format("YYYY-MM-DD"),
      "Current Period": findInCurrent(startDate, data),
      "Previous Period": findInPrevious(previousDates.start_datetime, data),
      currentDate: moment(startDate).format("YYYY-MM-DD"),
      previousDate: previousDates.start_datetime
    });
  }
  for (let i = 1; i <= diff; i++) {
    let currentDay = moment(startDate, "YYYY-MM-DD")
      .add(i, "days")
      .format("YYYY-MM-DD");
    let previousDay = moment(previousDates.start_datetime, "YYYY-MM-DD")
      .add(i, "days")
      .format("YYYY-MM-DD");
    const currentValue = findInCurrent(currentDay, data);
    const previousValue = findInPrevious(previousDay, data);

    if (currentValue || previousValue) {
      transformedChartData.push({
        date: currentDay,
        "Current Period": currentValue,
        "Previous Period": previousValue,
        currentDate: currentDay,
        previousDate: previousDay
      });
    }
  }
  return transformedChartData;
};
