import { fromPairs, toPairs } from "lodash";
import React from "react";
// ==================================================================
export const transFormReport = data => {
  return data.map(entry => {
    let newEntry = toPairs(entry);
    const hello = newEntry.splice(0, 1);
    newEntry = toPairs(entry).sort((a, b) => {
      if (a[1] < b[1]) {
        return 1;
      } else {
        return -1;
      }
    });
    let fixedCategories = 20;
    let others = 0;
    const newData = newEntry.map((c, i) => {
      if (i <= fixedCategories) {
        return c;
      }
      others += Number(c[1]);
      return c;
    });
    newData.splice(fixedCategories);
    newData.push(["Other", others.toFixed(2)]);
    newData.unshift(["month", hello[0][1]]);
    return fromPairs(newData);
  });
};
// ==================================================================
export const renderLegend = props => {
  const { payload, colors } = props;

  return (
    <ul className="sales-report-legend">
      {payload.map((entry, index) => (
        <li className="legend-item" key={`item-${index}`}>
          <span
            className="circle"
            style={{ backgroundColor: colors[entry.value] || "#000" }}
          ></span>
          <span className="legend-value">{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};
// ==================================================================
export const getNewCategories = (receiptsCategories, fixedCategories) => {
  let others = 0;
  const newData =
    receiptsCategories &&
    receiptsCategories.map((c, i) => {
      if (i <= fixedCategories) {
        return c;
      }
      others += Number(c.total_receipts);
      return c;
    });
  if (newData) {
    newData.splice(fixedCategories);
    newData.push({
      total_receipts: others,
      brand_category: "Other"
    });
  }
  return newData.length && newData.map(entry => entry.brand_category);
};
// ==================================================================
