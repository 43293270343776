import { humanizeCurrency } from "../../../utils";
import { chunk } from "lodash";
export const getAverageBasketDataSet = chart => {
  const baskets = chart && chart.length ? chart.map(c => c.bucket_size) : [];
  const frequencies =
    chart && chart.length
      ? chart.map(c =>
          humanizeCurrency({ amount: c.frequency, type: "number" })
        )
      : [];
  return [
    {
      columns: ["Basket Size"],
      data: baskets.length ? [[""], ...chunk(baskets, 1)] : []
    },
    {
      xSteps: 2,
      ySteps: -(baskets.length + 2),
      columns: ["Count"],
      data: frequencies.length ? [[""], ...chunk(frequencies, 1)] : []
    }
  ];
};
