import { CALL_API } from "../../apiMiddleware";

export const FETCH_RECEIPTS_DETAILS = "FETCH_RECEIPTS_DETAILS";
export const FETCH_RECEIPTS_DETAILS_SUCCESS = "FETCH_RECEIPTS_DETAILS_SUCCESS";
export const FETCH_RECEIPTS_DETAILS_FAILURE = "FETCH_RECEIPTS_DETAILS_FAILURE";

export const fetchReceiptDetails = (receipt_id, client_id) => ({
  [CALL_API]: {
    types: [
      FETCH_RECEIPTS_DETAILS,
      FETCH_RECEIPTS_DETAILS_SUCCESS,
      FETCH_RECEIPTS_DETAILS_FAILURE
    ],
    endpoint: `/receipts/${client_id}/${receipt_id}`,
    options: {
      method: "GET"
    }
  }
});
