import { chunk } from "lodash";
export const getFiltersDataSet = (selectedCategories, toDate, fromDate) => {
  const decodedCategories =
    selectedCategories && selectedCategories.length
      ? selectedCategories.map(category =>
          decodeURIComponent(category.toString())
        )
      : [];
  decodedCategories.unshift([""]);
  return [
    {
      columns: ["Categories"],
      data: decodedCategories ? chunk(decodedCategories, 1) : []
    },
    {
      xSteps: 3,
      ySteps: -(selectedCategories.length + 2),
      columns: ["From Date"],
      data: [[""], [fromDate]]
    },
    {
      xSteps: 5,
      ySteps: -3,
      columns: ["To Date"],
      data: [[""], [toDate]]
    }
  ];
};
