import { combineReducers } from "redux";
import { merchantsList } from "./merchantList";
import { merchantListFilters } from "./filters";
import { categories } from "./categories";

export const merchants = combineReducers({
  merchantsList,
  merchantListFilters,
  categories
});
