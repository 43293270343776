import React, { memo } from "react";
import { humanizeCurrency } from "../../../utils";
import Collapsible from "react-collapsible";
import ItemHeader from "./ItemHeader";
// =====================================================
export default memo(({ item: { name, price, description, quantity } }) => (
  <Collapsible
    className="hell"
    transitionTime={250}
    trigger={<ItemHeader name={name} price={price} quantity={quantity} />}
  >
    <>
      <div className="receipt-item">
        {description ? (
          <>
            <td>
              <span className="title">Description: </span>
              {description}
            </td>
          </>
        ) : null}

        {price ? (
          <>
            <td>
              <span className="title">Unit Price: </span>
              {humanizeCurrency({
                amount: price,
                type: "currency"
              })}
            </td>
          </>
        ) : null}
        {quantity ? (
          <>
            <td>
              <span className="title">Quantity: </span>
              {quantity}
            </td>
          </>
        ) : null}
      </div>
    </>
  </Collapsible>
));
