/* eslint-disable default-case */
import React, { useEffect, memo } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import _ from "underscore";
import { Link } from "react-router-dom";
import { makeQueryString, parseQueryString } from "../../utils";

import "../../styles/Modal.scss";

import {
  fetchTotalReceiptsDetails,
  fetchTotalItemsDetails,
  fetchAverageBasketDetails,
  fetchTotalSpentDetails,
  fetchAverageSpentDetails,
  fetchSalesFigures,
  fetchReceiptsBreakdown,
  fetchSalesReport,
  fetchTopMerchants,
  toggleCategoriesAll
} from "../../actions/dashboard";

import {
  setFromDate,
  setToDate,
  setDateRange,
  toggleCategory,
  setFilters,
  toggleFilterVisibility
} from "../../actions/dashboard/filters";

import TopRowFilters from "../../components/TopRowFilters";
import BasicStats from "../../components/BasicStats";
import SalesFiguresSection from "../../components/SalesFiguresSection";
import ReceiptsBreakdownSection from "../../components/ReceiptsBreakdownSection";
import SalesReportSection from "../../components/SalesReportSection";
import TopMerchantsSection from "../../components/TopMerchantsSection";
import { mapCategoriesToColors } from "../../utils";
// ========================================================================
const Home = ({
  dispatch,
  location: { search },
  filters,
  history: { push },
  filters: { selectedCategories },
  dashboard: {
    categories: { data },
    basicStats,
    salesFigures,
    categories,
    receiptsBreakdown,
    salesReport,
    topMerchants
  }
}) => {
  // =================================================

  const getBasicStatsAndSalesFigures = filters => {
    dispatch(fetchTotalReceiptsDetails(filters));
    dispatch(fetchTotalItemsDetails(filters));
    dispatch(fetchSalesFigures(filters));
    dispatch(fetchAverageBasketDetails(filters));
    dispatch(fetchTotalSpentDetails(filters));
    dispatch(fetchAverageSpentDetails(filters));
  };
  // =================================================
  const handleChangeStartDate = date => {
    const fromDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeQueryString({ ...filters, fromDate });
    push(`/?${qs}`);
    dispatch(setFromDate(fromDate));
  };
  // =================================================
  const handleChangeEndDate = date => {
    const toDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeQueryString({ ...filters, toDate });
    push(`/?${qs}`);
    dispatch(setToDate(toDate));
  };
  // =================================================
  const handleDateListClick = numDays => {
    let fromDate = Moment("01/01/2016", "DD/MM/YYYY").format("YYYY-MM-DD");
    if (numDays !== -1) {
      fromDate = Moment()
        .subtract(numDays, "days")
        .format("YYYY-MM-DD");
    }
    const toDate = Moment().format("YYYY-MM-DD");
    const qs = makeQueryString({ ...filters, fromDate, toDate });
    push(`/?${qs}`);
    dispatch(setDateRange(fromDate, toDate));
  };
  // =================================================
  const toggleFilter = () => {
    dispatch(toggleFilterVisibility());
  };
  // =================================================
  const handleCategoryChange = (newValue, categoryName) => {
    dispatch(toggleCategory(categoryName));
  };
  // =================================================
  const handleFilterButton = e => {
    const qs = makeQueryString(filters);
    push(`/?${qs}`);
    getBasicStatsAndSalesFigures(filters);
    dispatch(toggleFilterVisibility());
  };
  // =================================================
  const toggleAllCategories = () => {
    if (selectedCategories.length > 0) {
      dispatch(toggleCategoriesAll([]));
    } else {
      dispatch(toggleCategoriesAll(data));
    }
  };
  // =================================================
  const selectCategories = (allCategories, filterSelectedCategories) => {
    return allCategories.map((c, i) => {
      return i % 2 === 0 ? (
        <div className="filter-dropdown-left">
          <li>
            <label>
              <input
                type="checkbox"
                checked={_.contains(filterSelectedCategories, c)}
                onChange={e => handleCategoryChange(e.target.checked, c)}
              ></input>
              <span className="fake-input"></span>
              {decodeURIComponent(c)}
            </label>
          </li>
        </div>
      ) : (
        <div className="filter-dropdown-right">
          <li>
            <label>
              <input
                type="checkbox"
                checked={_.contains(filterSelectedCategories, c)}
                onChange={e => handleCategoryChange(e.target.checked, c)}
              ></input>
              <span className="fake-input"></span>
              {decodeURIComponent(c)}
            </label>
          </li>
        </div>
      );
    });
  };
  // =================================================
  useEffect(() => {
    if (!search) {
      const qs = makeQueryString(filters);
      push(`/?${qs}`);
    } else {
      const parsedQs = parseQueryString(search);
      dispatch(setFilters(parsedQs));
      getBasicStatsAndSalesFigures(parsedQs);
    }
  }, [search]);
  // =================================================
  useEffect(() => {
    dispatch(fetchReceiptsBreakdown());
    dispatch(fetchSalesReport());
    dispatch(fetchTopMerchants());
  }, []);
  // =================================================
  const colors =
    receiptsBreakdown.isDataAvailable &&
    mapCategoriesToColors(receiptsBreakdown.data);
  // =================================================
  return (
    <div style={{ minHeight: "900px" }} className="content-scroll">
      <div className="content-width">
        <div className="top-heah">
          <div className="head-left">
            <h1>Overview</h1>
          </div>
          <div className="head-right">
            <TopRowFilters
              filters={filters}
              handleDateListClick={handleDateListClick}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
              toggleFilter={toggleFilter}
              categories={categories}
              selectCategories={selectCategories}
              toggleAllCategories={toggleAllCategories}
              handleFilterButton={handleFilterButton}
              type="dashboard"
            />
          </div>
        </div>
        <BasicStats
          basicStats={basicStats}
          filters={filters}
          categories={categories}
          colors={colors}
        />
        <SalesFiguresSection {...{ ...salesFigures, ...filters }} />
        <div className="two-cols">
          <ReceiptsBreakdownSection {...receiptsBreakdown} colors={colors} />
          <SalesReportSection
            {...salesReport}
            receiptsBreakdown={receiptsBreakdown}
            colors={colors}
          />
        </div>
        <TopMerchantsSection {...topMerchants} />
        <div className="view-all">
          <Link to="/merchants">VIEW ALL MERCHANTS</Link>
        </div>
      </div>
    </div>
  );
  // =================================================
};
// ========================================================================

const mapStateToProps = state => {
  const { filters, dashboard } = state;
  return {
    filters,
    dashboard
  };
};
// ========================================================================

export default memo(connect(mapStateToProps)(Home));
