import React, { memo } from "react";

const FilterCategories = memo(
  ({
    toggleFilter,
    showFilter,
    selectCategories,
    allCategories,
    toggleAllCategories,
    filterSelectedCategories,
    handleFilterButton,
    type
  }) => (
    <div className={`filter-area ${showFilter && "drop-active"}`}>
      <div className="filter-link" onClick={toggleFilter}>
        <i className="icon-filter"></i>
      </div>
      {showFilter && (
        <>
          <div className="overlay" onClick={toggleFilter}></div>
          <div className="filter-dropdown">
            <div className="drop-head">
              <span href="#" className="close" onClick={toggleFilter}>
                <i className="icon-close"></i>
              </span>
              <h4>Filters</h4>
            </div>
            <ul
              className="checkbox-list"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between"
              }}
            >
              {selectCategories(allCategories, filterSelectedCategories)}
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              {type === "dashboard" && (
                <div>
                  <a
                    href="#/"
                    onClick={toggleAllCategories}
                    className="filter-bt"
                  >
                    {filterSelectedCategories.length > 0
                      ? "Clear All"
                      : "Select All"}
                  </a>
                </div>
              )}

              <div>
                {type === "dashboard" ? (
                  <button
                    onClick={
                      filterSelectedCategories.length > 0 && handleFilterButton
                    }
                    className={
                      filterSelectedCategories.length > 0
                        ? "filter-btn"
                        : "filter-btn-disabled"
                    }
                    disabled={!(selectCategories.length > 0)}
                  >
                    Filter
                  </button>
                ) : (
                  <button onClick={handleFilterButton} className="filter-btn">
                    Filter
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
);

export default FilterCategories;
