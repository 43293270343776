export const headers = [
  {
    label: "file name",
    value: "request_id"
  },
  {
    label: "store",
    value: "store_name"
  },
  {
    label: "date",
    value: "date"
  },
  {
    label: "basket size",
    value: "basket_size"
  },
  {
    label: "total amount",
    value: "total_amount"
  },
  {
    label: "",
    value: ""
  }
];
