import React from "react";
import Modal from "react-modal";
import { isEmpty } from "lodash";
import { humanizeCurrency, transformBasicStatsChartData } from "../../utils";
// ===========================================
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import NoData from "./NoData";
// ===========================================
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "99"
  }
};
// ===========================================
const AnalyticsModal = ({
  handleClose,
  data,
  title,
  subtitle,
  showModal,
  graphType,
  dataKey,
  contentType,
  xAxisDataKey,
  colors
}) => {
  // ===========================================
  const {
    data: { chart, tile }
  } = data || {};
  const newChart = transformBasicStatsChartData(chart);
  // ===========================================

  return (
    <Modal
      isOpen={showModal}
      style={customStyles}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className="modal-head add">
        <div className="action-area">
          <button onClick={handleClose} className="icon-close"></button>
        </div>
        <h1>{title}</h1>
        <span className="des">{subtitle}</span>
      </div>
      {/* tileTitle,
                tileInteger,
                tileIntegerLastTimePeriod,
                graphType, */}
      <div className="modal-content">
        <div className="overview-modal-info">
          <div className="left-info">
            <div
              className={`detail-block ${tile.changeIsPositive ? "" : "low"}`}
            >
              <span className="prograss-value">{tile.changePercentage}%</span>
              <div className="info-det">
                <span className="title">{title}</span>
                <span className="value">
                  {humanizeCurrency({
                    amount: tile.currentValue,
                    type: contentType
                  })}
                </span>
                <span className="text">
                  {humanizeCurrency({
                    amount: tile.previousValue,
                    type: contentType
                  })}{" "}
                  in {tile.previousDurationText}
                </span>
              </div>
            </div>
          </div>
          <div className="right-info">
            <h2>&nbsp;</h2>
            {/* {tile.previousDurationText.toUpperCase()} */}
            {!chart || isEmpty(chart) ? (
              <NoData />
            ) : (
              <div className="graph-area">
                {graphType === "PIE" && (
                  <PieChart
                    colors={colors}
                    newChart={newChart}
                    dataKey={dataKey}
                  />
                )}

                {graphType === "BAR" && (
                  <BarChart
                    chart={chart}
                    xAxisDataKey={xAxisDataKey}
                    dataKey={dataKey}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AnalyticsModal;
