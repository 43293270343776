// =======================================================================
export const makeQueryString = filters => {
  const { fromDate, toDate, selectedCategories } = filters;
  return `fromDate=${fromDate}&toDate=${toDate}&selectedCategories=${selectedCategories.join(
    ","
  )}`;
};
// =======================================================================

export const parseQueryString = qs => {
  const str = qs.substring(1, qs.length);
  const paramArray = str.split("&");
  const r = {};
  paramArray.forEach(param => {
    const [key, value] = param.split("=");
    if (key === "selectedCategories") {
      r[key] = value.split(",");
    } else {
      r[key] = value;
    }
  });

  return r;
};
// =======================================================================
