import moment from "moment";
import * as ActionTypes from "../../actions/dashboard/filters";
import { FETCH_ALL_CATEGORIES_SUCCESS } from "../../actions/dashboard";

const initialState = {
  fromDate: moment()
    .subtract(7, "days")
    .format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
  selectedCategories: [],
  visible: false
};

const {
  SET_TO_DATE,
  SET_FROM_DATE,
  SET_DATERANGE,
  TOGGLE_CATEGORY,
  TOGGLE_CATEGORIES_ALL,
  CLEAR_CATEGORIES,
  TOGGLE_FILTER_VISIBILITY,
  SET_FILTERS,
  RESET_FILTERS
} = ActionTypes;

const filters = (state = initialState, action) => {
  switch (action.type) {
    case SET_TO_DATE:
      return { ...state, toDate: action.payload };

    case SET_FROM_DATE:
      return { ...state, fromDate: action.payload };

    case SET_DATERANGE:
      return {
        ...state,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate
      };

    case TOGGLE_CATEGORY: {
      const { selectedCategories } = state;
      if (selectedCategories.indexOf(action.payload) === -1) {
        return {
          ...state,
          selectedCategories: [...state.selectedCategories, action.payload]
        };
      } else {
        return {
          ...state,
          selectedCategories: [...state.selectedCategories].filter(
            item => item !== action.payload
          )
        };
      }
    }

    case TOGGLE_CATEGORIES_ALL:
      return { ...state, selectedCategories: action.payload };

    case FETCH_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        selectedCategories: action.payload.data.map(item =>
          encodeURIComponent(item)
        )
      };

    case CLEAR_CATEGORIES:
      return { ...state, selectedCategories: [] };

    case TOGGLE_FILTER_VISIBILITY:
      return { ...state, visible: !state.visible };

    case SET_FILTERS:
      return { ...state, ...action.payload };

    case RESET_FILTERS:
      return { ...initialState, ...action.payload };

    default:
      return state;
  }
};

export { filters };
