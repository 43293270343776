import React, { useState } from "react";
import BasicStatTile from "../BasicStatsTile";
import AnalyticsModal from "../AnalyticsModal";
import { propertyMap, modalConfig, tiles } from "./config";

const BasicStats = ({
  basicStats,
  filters: { fromDate, toDate },
  categories,
  colors,
  isModalDisabled,
  merchantClass
}) => {
  const [isModalVisible, toggleModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const handleTileClick = tile => {
    toggleModal(!isModalVisible);
    setActiveModal(tile);
  };
  return (
    <>
      {!isModalDisabled && isModalVisible && (
        <AnalyticsModal
          {...modalConfig[activeModal]}
          data={basicStats[propertyMap[activeModal]]}
          colors={colors}
          fromDate={fromDate}
          toDate={toDate}
          categories={categories}
          showModal={isModalVisible}
          handleClose={() => {
            toggleModal(!isModalVisible);
          }}
        />
      )}
      <ul className="overview-info">
        {tiles.map(item =>
          basicStats[propertyMap[item.tileType]].data ? (
            <li
              key={item.id}
              onClick={() => handleTileClick(item.tileType)}
              className={merchantClass}
            >
              <BasicStatTile
                isLoading={basicStats[propertyMap[item.tileType]].isLoading}
                title={item.title}
                type={item.type}
                tileData={basicStats[propertyMap[item.tileType]].data.tile}
                isDataAvailable={
                  basicStats[propertyMap[item.tileType]].isDataAvailable
                }
              />
            </li>
          ) : (
            []
          )
        )}
      </ul>
    </>
  );
};

export default BasicStats;
