import * as ActionTypes from "../../actions/merchants/categories";
const {
  FETCH_MERCHANT_LIST_ALL_CATEGORIES,
  FETCH_MERCHANT_LIST_ALL_CATEGORIES_SUCCESS,
  FETCH_MERCHANT_LIST_ALL_CATEGORIES_FAILURE
} = ActionTypes;

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
};

export const categories = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANT_LIST_ALL_CATEGORIES:
      return { ...state, isLoading: true };

    case FETCH_MERCHANT_LIST_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDataAvailable: true,
        data: action.payload.data.map(item => encodeURIComponent(item))
      };

    case FETCH_MERCHANT_LIST_ALL_CATEGORIES_FAILURE:
      return { ...state, isLoading: false, isDataAvailable: false };

    default:
      return state;
  }
};
