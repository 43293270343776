import React, { useEffect, memo } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import TopRowFilters from "../../components/TopRowFilters";
import BasicStats from "../../components/BasicStats";
import SalesFiguresSection from "../../components/SalesFiguresSection";
import ReceiptListTable from "../Receipts/ReceiptListTable";
import { fetchReceiptDetails } from "../../actions/receipts";
import { Link } from "react-router-dom";

import "../../styles/Modal.scss";
import { fetchReceiptsBreakdown } from "../../actions/dashboard";
import {
  makeMerchantDetailsQueryString,
  parseMerchantDetailsQueryString,
  mapCategoriesToColors
} from "../../utils";
import {
  setFromDate, setToDate, setDateRange, setFilters
} from "../../actions/dashboard/filters";
// import {
//   setMerchantDetailsFromDate,
//   setMerchantDetailsToDate,
//   setMerchantDetailsDateRange,
//   setMerchantDetailsFilters
// } from "../../actions/merchant-details/filters";
import {
  fetchTotalReceiptsDetails,
  fetchTotalItemsDetails,
  fetchAverageBasketDetails,
  fetchTotalSpentDetails,
  fetchSalesFigures,
  fetchAverageSpentDetails,
  fetchRecentReceipts
} from "../../actions/merchant-details";
import "../../styles/Modal.scss";
// ========================================================
const MerchantDetail = ({
  dispatch,
  history: { push },
  dashboard: { receiptsBreakdown },
  filters,
  merchantDetails: {
    basicStats,
    salesFigures,
    recentReceipts
  },
  match: {
    params: { merchantName }
  },
  location: { search }
}) => {
  const { toDate, fromDate } = filters;
  const salesFigureFilters = {
    ...filters,
    toDate: toDate,
    fromDate: fromDate
  };
  const colors =
    receiptsBreakdown.isDataAvailable &&
    mapCategoriesToColors(receiptsBreakdown.data);
  // ==================================================
  const getBasicStatsAndSalesFigures = filters => {
    dispatch(fetchRecentReceipts(filters));
    dispatch(fetchTotalReceiptsDetails(filters));
    dispatch(fetchTotalItemsDetails(filters));
    dispatch(fetchSalesFigures(filters));
    dispatch(fetchAverageBasketDetails(filters));
    dispatch(fetchTotalSpentDetails(filters));
    dispatch(fetchAverageSpentDetails(filters));
  };
  // ==================================================
  const handleChangeStartDate = date => {
    const fromDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeMerchantDetailsQueryString({
      ...filters,
      fromDate
    });
    push(`/merchants/${merchantName}?${qs}`);
    dispatch(setFromDate(fromDate));
  };
  // ==================================================
  const handleChangeEndDate = date => {
    const toDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeMerchantDetailsQueryString({
      ...filters,
      toDate
    });
    push(`/merchants/${merchantName}?${qs}`);
    dispatch(setToDate(toDate));
  };
  // ==================================================
  const handleDateListClick = numDays => {
    let fromDate = Moment("01/01/2016", "DD/MM/YYYY").format("YYYY-MM-DD");
    if (numDays !== -1) {
      fromDate = Moment()
        .subtract(numDays, "days")
        .format("YYYY-MM-DD");
    }
    const toDate = Moment().format("YYYY-MM-DD");
    const qs = makeMerchantDetailsQueryString({
      ...filters,
      fromDate,
      toDate
    });
    push(`/merchants/${merchantName}?${qs}`);
    dispatch(setDateRange(fromDate, toDate));
    getBasicStatsAndSalesFigures({
      fromDate,
      toDate,
      store_name: merchantName
    });
  };
  // ==================================================
  const onSelectReceipt = ({ request_id, client_id }) => {
    dispatch(fetchReceiptDetails(request_id, client_id));
    push(`/receipts/${client_id}/${request_id}`);
  };
  // ==================================================
  useEffect(() => {
    if (!search) {
      const qs = makeMerchantDetailsQueryString(filters);
      push(`/merchants/${merchantName}?${qs}`);
    } else {
      const parsedQs = parseMerchantDetailsQueryString(search);

      const { fromDate, toDate } = parsedQs;
      dispatch(setFilters({...parsedQs, ...filters}));
      getBasicStatsAndSalesFigures({
        ...filters,
        fromDate,
        toDate,
        store_name: merchantName
      });
    }
    dispatch(fetchReceiptsBreakdown());
  }, [search]);
  // ==================================================
  return (
    <div style={{ minHeight: "900px" }} className="content-scroll">
      <div className="content-width">
        <div className="top-heah">
          <div className="head-left">
            <h1>{merchantName.toUpperCase()}</h1>
          </div>
          <div className="head-right">
            <TopRowFilters
              filters={filters}
              handleDateListClick={handleDateListClick}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
              type="merchant-details"
            />
          </div>
        </div>
        <BasicStats
          basicStats={basicStats}
          filters={filters}
          isModalDisabled={true}
          merchantClass="merchant-details-hover"
          colors={colors}
        />
        <SalesFiguresSection {...{ ...salesFigures, ...salesFigureFilters }} />
        <div className="section">
          <h2>Recent Receipts</h2>
          <ReceiptListTable
            receipts={recentReceipts}
            onSelectReceipt={onSelectReceipt}
            source="merchants"
          />

          <div className="view-all">
            <Link to="/receipts">View All Receipts</Link>
          </div>
          <div className="merchants-list-link">
            <Link to="/merchants">
              <div>
                <span>
                  <i class="fas fa-angle-left"></i>
                </span>
                <span>Merchants List</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
// ===============================================================
const mapStateToProps = state => state;
export default memo(connect(mapStateToProps)(MerchantDetail));
