import { combineReducers } from 'redux'

import { createReducer } from '../../utils'

import { basicStats } from './basicStats'
import { categories } from './categories'
import { salesFigures } from './salesFigures'

import * as ActionTypes from '../../actions/dashboard'

const receiptsBreakdown = createReducer([
  ActionTypes.FETCH_RECEIPTS_BREAKDOWN,
  ActionTypes.FETCH_RECEIPTS_BREAKDOWN_SUCCESS,
  ActionTypes.FETCH_RECEIPTS_BREAKDOWN_FAILURE
])

const salesReport = createReducer([
  ActionTypes.FETCH_SALES_REPORT,
  ActionTypes.FETCH_SALES_REPORT_SUCCESS,
  ActionTypes.FETCH_SALES_REPORT_FAILURE
])

const topMerchants = createReducer([
  ActionTypes.FETCH_TOP_MERCHANTS,
  ActionTypes.FETCH_TOP_MERCHANTS_SUCCESS,
  ActionTypes.FETCH_TOP_MERCHANTS_FAILURE
])

export const dashboard = combineReducers({
  basicStats,
  categories,
  salesFigures,
  receiptsBreakdown,
  salesReport,
  topMerchants
})
