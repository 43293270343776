import { humanizeCurrency } from "../../../utils";
import { chunk } from "lodash";
import { number } from "prop-types";
export const getTopMerchantsDataset = merchants => {
  const names = merchants.length && merchants.map(m => m.store_name);
  const categories = merchants.length && merchants.map(m => m.category);
  const items =
    merchants.length &&
    merchants.map(m =>
      humanizeCurrency({ amount: m.items_sold, type: number })
    );
  const buckets = merchants.length && merchants.map(m => m.average_bucket_size);
  const revenues =
    merchants.length &&
    merchants.map(m => humanizeCurrency({ amount: m.revenue, type: "number" }));
  return [
    {
      columns: ["Store Name"],
      data: [[""], ...chunk(names, 1)]
    },
    {
      xSteps: 3,
      ySteps: -(names.length + 2),
      columns: ["Category"],
      data: [[""], ...chunk(categories, 1)]
    },
    {
      xSteps: 6,
      ySteps: -(names.length + 2),
      columns: ["Items Sold"],
      data: [[""], ...chunk(items, 1)]
    },
    {
      xSteps: 8,
      ySteps: -(names.length + 2),
      columns: ["Average Basket Size"],
      data: [[""], ...chunk(buckets, 1)]
    },
    {
      xSteps: 11,
      ySteps: -(revenues.length + 2),
      columns: ["Revenue"],
      data: [[""], ...chunk(revenues, 1)]
    }
  ];
};
