import React from "react";
import Loader from "react-loader-spinner";

const SpiralLoader = ({ height, width }) => {
  return(
    <Loader
    type="TailSpin"
    color="#FFF"
    height={height}
    width={width}
  />
  )
}

export default SpiralLoader