import React, { useEffect, memo } from "react";
import { connect } from "react-redux";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import EmaarTable from "../../components/EmaarTable";
import TopRowFilters from "../../components/TopRowFilters";
import Moment from "moment";
import _ from "underscore";
import EmaarLoader from "../../components/EmaarLoader";
import { fetchMerchantsList } from "../../actions/merchants";

import {
  toggleMerchantListCategoriesAll,
  setMerchantListFilters,
  setStore,
  toggleMerchantListCategory,
  setLimit,
  setOffset,
  toggleMerchantListFilterVisibility,
  setMerchantListSortBy,
  setMerchantListSortOrder
} from "../../actions/merchants/filters";
import {
  setToDate,
  setFromDate,
  setDateRange,
  setFilters
} from "../../actions/dashboard/filters";
import { makeMerchantQueryString, parseMerchantQueryString } from "../../utils";

const MerchantList = ({
  dispatch,
  location: { search },
  filters,
  merchants: {
    categories,
    merchantListFilters,
    merchantListFilters: { merchantCategories, sort_order, sort_by },
    merchantsList: { isDataAvailable, data, isLoading }
  },
  history: { push }
}) => {
  // ===================================================
  const styles = {
    width: "100%",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  // ===================================================
  const handleFilterButton = e => {
    const qs = makeMerchantQueryString({
      ...merchantListFilters,
      merchantCategories
    }, filters);
    dispatch(toggleMerchantListFilterVisibility());
    dispatch(
      fetchMerchantsList({ ...merchantListFilters,...filters, merchantCategories })
    );
    push(`/merchants?${qs}`);
  };
  // ===================================================
  const handleCategoryChange = (newValue, categoryName) => {
    dispatch(toggleMerchantListCategory(categoryName));
  };
  // ===================================================
  const selectCategories = (allCategories, filterSelectedCategories) => {
    return allCategories.map((c, i) => {
      return i % 2 === 0 ? (
        <div className="filter-dropdown-left">
          <li>
            <label>
              <input
                type="checkbox"
                checked={_.contains(filterSelectedCategories, c)}
                onChange={e => handleCategoryChange(e.target.checked, c)}
              ></input>
              <span className="fake-input"></span>
              {decodeURIComponent(c)}
            </label>
          </li>
        </div>
      ) : (
        <div className="filter-dropdown-right">
          <li>
            <label>
              <input
                type="checkbox"
                checked={_.contains(filterSelectedCategories, c)}
                onChange={e => handleCategoryChange(e.target.checked, c)}
              ></input>
              <span className="fake-input"></span>
              {decodeURIComponent(c)}
            </label>
          </li>
        </div>
      );
    });
  };
  // ===================================================
  const toggleFilter = () => {
    dispatch(toggleMerchantListFilterVisibility());
  };
  // ===================================================
  const toggleAllCategories = () => {
    if (merchantCategories.length > 0) {
      dispatch(toggleMerchantListCategoriesAll([]));
    } else {
      dispatch(toggleMerchantListCategoriesAll(categories.data));
    }
  };
  // ===================================================
  const handleLimitChange = limit => {
    dispatch(setLimit(limit));
    dispatch(setOffset(0));
    const qs = makeMerchantQueryString({
      ...merchantListFilters,
      limit,
      offset: 0
    },filters);
    push(`/merchants?${qs}`);
    dispatch(fetchMerchantsList({ ...merchantListFilters, ...filters ,limit, offset: 0 }));
  };
  // ===================================================
  const handleSearchChange = store_name => {
    dispatch(setStore(store_name));
    const qs = makeMerchantQueryString({
      ...merchantListFilters,
      store_name
    }, filters);
    push(`/merchants?${qs}`);
    dispatch(fetchMerchantsList({ ...merchantListFilters, ...filters ,store_name }));
  };
  // ===================================================
  const handleChangeStartDate = date => {
    const fromDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeMerchantQueryString(merchantListFilters, {
      ...filters,
      fromDate
    });
    push(`/merchants?${qs}`);
    dispatch(setFromDate(fromDate));
    dispatch(
      fetchMerchantsList({
        ...merchantListFilters,
        ...filters,
        fromDate
      })
    );
  };
  // ===================================================
  const handleChangeEndDate = date => {
    const toDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeMerchantQueryString(merchantListFilters, {
      ...filters,
      toDate
    });

    push(`/merchants?${qs}`);
    dispatch(setToDate(toDate));
    dispatch(
      fetchMerchantsList({
        ...merchantListFilters,
        ...filters,
        toDate
      })
    );
  };
  // ===================================================
  const handleDateListClick = numDays => {
    let fromDate = Moment("01/01/2016", "DD/MM/YYYY").format("YYYY-MM-DD");
    if (numDays !== -1) {
      fromDate = Moment()
        .subtract(numDays, "days")
        .format("YYYY-MM-DD");
    }

    const toDate = Moment().format("YYYY-MM-DD");
    const qs = makeMerchantQueryString(
      merchantListFilters,
      filters,
      fromDate,
      toDate
    );
    push(`/merchants?${qs}`);
    dispatch(setDateRange(fromDate, toDate));
    dispatch(
      fetchMerchantsList({
        ...merchantListFilters,
        ...filters,
        fromDate,
        toDate
      })
    );
  };
  // ===================================================
  const handleChangeOffset = offset => {
    dispatch(setOffset(offset));
    const qs = makeMerchantQueryString({
      ...merchantListFilters,
      offset
    }, filters);
    push(`/merchants?${qs}`);
    dispatch(fetchMerchantsList({ ...merchantListFilters, ...filters ,offset }));
  };
  // ===================================================
  const handleSortBy = sort_by => {
    const { sort_order } = merchantListFilters;
    const newSortOrder = sort_order === "DESC" ? "ASC" : "DESC";
    const qs = makeMerchantQueryString({
      ...merchantListFilters,
      sort_by,
      sort_order: newSortOrder
    }, filters);
    push(`/merchants?${qs}`);
    dispatch(setMerchantListSortBy(sort_by));
    dispatch(setMerchantListSortOrder(newSortOrder));
    dispatch(
      fetchMerchantsList({
        ...merchantListFilters,
        ...filters,
        sort_by,
        sort_order: newSortOrder
      })
    );
  };
  // ===================================================
  const onMerchantClick = store => {
    push(`/merchants/${store.store}`);
  };
  // ===================================================
  useEffect(() => {
    if (!search) {
      const qs = makeMerchantQueryString(merchantListFilters, filters);
      push(`/merchants?${qs}`);
    } else {
      const parsedQs = parseMerchantQueryString(search);
      const {
        limit,
        offset,
        merchantCategories,
        store_name,
        sort_by,
        sort_order,
        toDate,
        fromDate,
        selectedCategories
      } = parsedQs;
      dispatch(
        setMerchantListFilters({
          limit,
          offset,
          merchantCategories,
          store_name,
          sort_by,
          sort_order
        })
      );
      // dispatch(setMerchantListFilters(parsedQs));
      dispatch(setFilters({ toDate, fromDate, selectedCategories }));
      dispatch(fetchMerchantsList(parsedQs));
    }
  }, [search]);
  // ===================================================
  return (
    <div className="content-scroll">
      <div className="content-width">
        <div className="top-heah">
          <div className="head-left">
            <h1>Merchants</h1>
          </div>
          <div className="head-right">
            <TopRowFilters
              filters={{ ...merchantListFilters, ...filters }}
              merchantListFilters={merchantListFilters}
              handleDateListClick={handleDateListClick}
              handleChangeStartDate={handleChangeStartDate}
              handleChangeEndDate={handleChangeEndDate}
              toggleFilter={toggleFilter}
              categories={categories}
              selectCategories={selectCategories}
              handleFilterButton={handleFilterButton}
              toggleAllCategories={toggleAllCategories}
              type="merchant-list"
            />
          </div>
        </div>
        <div className={"section"}>
          <SearchBar
            handleSearchChange={handleSearchChange}
            store_name={merchantListFilters.store_name}
          />
          {isLoading ? (
            <div style={styles}>
              <EmaarLoader height={80} width={80} />
            </div>
          ) : (
            isDataAvailable && (
              <>
                <EmaarTable
                  headers={[
                    { label: "Store Name", value: "store" },
                    { label: "Category", value: "category" },
                    { label: "Items Sold", value: "items_sold" },
                    {
                      label: "AVG BASKET SIZE",
                      value: "average_basket_size"
                    },
                    { label: "REVENUE", value: "revenue" }
                  ]}
                  rowData={data}
                  onMerchantClick={onMerchantClick}
                  handleSortBy={handleSortBy}
                  sort_order={sort_order}
                  sort_by={sort_by}
                />
                <Pagination
                  handleLimitChange={handleLimitChange}
                  limit={merchantListFilters.limit}
                  offset={merchantListFilters.offset}
                  handleChangeOffset={handleChangeOffset}
                  pagination_count={data.length && data[0].pagination_count}
                  total_pages={
                    data.length &&
                    Math.ceil(
                      data[0].pagination_count / merchantListFilters.limit.value
                    )
                  }
                  pageNeighbours={3}
                />
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
  // ===================================================
};
const mapStateToProps = state => ({
  merchants: state.merchants,
  filters: state.filters
});
export default memo(connect(mapStateToProps)(MerchantList));
