import moment from "moment";
import * as ActionTypes from "../../actions/merchant-details/filters";

const initialState = {
  fromDate: moment()
    .subtract(7, "days")
    .format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD")
};

const {
  SET_MERCHANT_DETAILS_DATERANGE,
  SET_MERCHANT_DETAILS_FROM_DATE,
  SET_MERCHANT_DETAILS_TO_DATE,
  SET_MERCHANT_DETAILS_LIMIT,
  RESET_MERCHANT_DETAILS_FILTERS,
  SET_MERCHANT_DETAILS_FILTERS
} = ActionTypes;
const merchantDetailsFilters = (state = initialState, action) => {
  switch (action.type) {
    case SET_MERCHANT_DETAILS_TO_DATE:
      return { ...state, toDate: action.payload };

    case SET_MERCHANT_DETAILS_FROM_DATE:
      return { ...state, fromDate: action.payload };

    case SET_MERCHANT_DETAILS_DATERANGE:
      return {
        ...state,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate
      };
    case SET_MERCHANT_DETAILS_LIMIT:
      return { ...state, ...action.payload };

    case SET_MERCHANT_DETAILS_FILTERS:
      return { ...state, ...action.payload };

    case RESET_MERCHANT_DETAILS_FILTERS:
      return { ...initialState, ...action.payload };

    default:
      return state;
  }
};

export { merchantDetailsFilters };
