import { humanizeCurrency } from "../../../utils";
import { chunk } from "lodash";
export const getAverageSpentDataSet = chart => {
  const dates = chart && chart.length ? chart.map(c => c.date) : [];
  const spents =
    chart && chart.length
      ? chart.map(c =>
          humanizeCurrency({ amount: c.average_spent, type: "number" })
        )
      : [];
  return [
    {
      columns: ["Date"],
      data: dates.length ? [[""], ...chunk(dates, 1)] : []
    },
    {
      xSteps: 2,
      ySteps: -(dates.length + 2),
      columns: ["Amount"],
      data: spents.length ? [[""], ...chunk(spents, 1)] : []
    }
  ];
};
