import React, { memo } from 'react'
import EmaarLoader from '../EmaarLoader'
import TopMerchants from '../TopMerchants'

const TopMerchantsSection = memo( ({
  isLoading,
  isDataAvailable,
  data
}) => {
  return (
    <div className="section">
      <h2>Top Merchants</h2>
      {isLoading && <EmaarLoader />}
      {(!isLoading && !isDataAvailable) && <h1>No Data</h1>}
      {(!isLoading && isDataAvailable) && <TopMerchants topMerchants={data} />}

    </div>
  )
})

export default TopMerchantsSection