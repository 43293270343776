export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_TO_DATE = "SET_TO_DATE";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_MULTIPLE_CATEGORIES = "SET_MULTIPLE_CATEGORIES";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";
export const SET_DATERANGE = "SET_DATERANGE";
export const TOGGLE_FILTER_VISIBILITY = "TOGGLE_FILTER_VISIBILITY";
export const TOGGLE_CATEGORIES_ALL = "TOGGLE_CATEGORIES_ALL";
export const TOGGLE_CATEGORY = "TOGGLE_CATEGORY";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";

export const setFromDate = fromDate => ({
  type: SET_FROM_DATE,
  payload: fromDate
});

export const setToDate = toDate => ({
  type: SET_TO_DATE,
  payload: toDate
});

export const setDateRange = (fromDate, toDate) => ({
  type: SET_DATERANGE,
  payload: { fromDate, toDate }
});

export const setCategory = category => ({
  type: SET_CATEGORY,
  payload: category
});

export const clearCategories = () => ({
  type: CLEAR_CATEGORIES
});

export const toggleFilterVisibility = () => ({
  type: TOGGLE_FILTER_VISIBILITY
});

export const toggleCategoriesAll = payload => ({
  type: TOGGLE_CATEGORIES_ALL,
  payload
});

export const toggleCategory = payload => ({
  type: TOGGLE_CATEGORY,
  payload
});

export const setFilters = filters => ({
  type: SET_FILTERS,
  payload: filters
});

export const resetFilters = filters => ({
  type: RESET_FILTERS,
  payload: filters
});
