import { combineReducers } from "redux";

import * as ActionTypes from "../../actions/merchant-details/basicStats";
import { createReducer } from "../../utils";

const totalReceipts = createReducer([
  ActionTypes.FETCH_TOTAL_RECEIPTS_DETAILS,
  ActionTypes.FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS,
  ActionTypes.FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE
]);

const totalItems = createReducer([
  ActionTypes.FETCH_TOTAL_ITEMS_DETAILS,
  ActionTypes.FETCH_TOTAL_ITEMS_DETAILS_SUCCESS,
  ActionTypes.FETCH_TOTAL_ITEMS_DETAILS_FAILURE
]);
const averageBasket = createReducer([
  ActionTypes.FETCH_AVERAGE_BASKET_DETAILS,
  ActionTypes.FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS,
  ActionTypes.FETCH_AVERAGE_BASKET_DETAILS_FAILURE
]);
const totalSpent = createReducer([
  ActionTypes.FETCH_TOTAL_SPENT_DETAILS,
  ActionTypes.FETCH_TOTAL_SPENT_DETAILS_SUCCESS,
  ActionTypes.FETCH_TOTAL_SPENT_DETAILS_FAILURE
]);
const averageSpent = createReducer([
  ActionTypes.FETCH_AVERAGE_SPENT_DETAILS,
  ActionTypes.FETCH_AVERAGE_SPENT_DETAILS_SUCCESS,
  ActionTypes.FETCH_AVERAGE_SPENT_DETAILS_FAILURE
]);
const basicStats = combineReducers({
  totalReceipts,
  totalItems,
  averageBasket,
  totalSpent,
  averageSpent
});

export { basicStats };
