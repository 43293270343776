import React, { memo } from "react";
import PropTypes from "prop-types";
import EmaarLoader from "../EmaarLoader";
import { humanizeCurrency } from "../../utils";
import { isEmpty } from "lodash";
const BasicStatTile = memo(props => {
  const { title, tileData, isLoading, type } = props;
  const previousText =
    !isEmpty(tileData) && tileData.previousDurationText
      ? `${humanizeCurrency({
          amount: tileData.previousValue,
          type
        })} in ${tileData.previousDurationText}`
      : "";
  return (
    <div>
      {isLoading ? (
        <EmaarLoader />
      ) : (
        !isEmpty(tileData) && (
          <>
            <h2>{title}</h2>
            <div className="values">
              <span className="value">
                {humanizeCurrency({ amount: tileData.currentValue, type })}
              </span>
              <br />
              <span
                className={`${tileData.changeIsPositive ? "" : "low"} ${
                  tileData.changePercentage ? "status" : ""
                }`}
              >
                {tileData.changePercentage &&
                  `${tileData.changeIsPositive ? "+" : "-"}${humanizeCurrency({
                    amount: tileData.changePercentage,
                    type: "number"
                  })}%`}
              </span>
            </div>
            <span className="text">{previousText}</span>
          </>
        )
      )}
    </div>
  );
});

BasicStatTile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDataAvailable: PropTypes.bool.isRequired,
  title: PropTypes.string,
  currentValue: PropTypes.string,
  changePercentage: PropTypes.string,
  changeIsPositive: PropTypes.bool,
  previousValue: PropTypes.string,
  previousDurationText: PropTypes.string
};

export default BasicStatTile;
