import { CALL_API } from '../../apiMiddleware'

export const FETCH_TOTAL_RECEIPTS_DETAILS = 'FETCH_TOTAL_RECEIPTS_DETAILS'
export const FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS = 'FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS'
export const FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE = 'FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE'

export const FETCH_TOTAL_ITEMS_DETAILS = 'FETCH_TOTAL_ITEMS_DETAILS'
export const FETCH_TOTAL_ITEMS_DETAILS_SUCCESS = 'FETCH_TOTAL_ITEMS_DETAILS_SUCCESS'
export const FETCH_TOTAL_ITEMS_DETAILS_FAILURE = 'FETCH_TOTAL_ITEMS_DETAILS_FAILURE'

export const FETCH_AVERAGE_BASKET_DETAILS = 'FETCH_AVERAGE_BASKET_DETAILS'
export const FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS = 'FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS'
export const FETCH_AVERAGE_BASKET_DETAILS_FAILURE = 'FETCH_AVERAGE_BASKET_DETAILS_FAILURE'

export const FETCH_TOTAL_SPENT_DETAILS = 'FETCH_TOTAL_SPENT_DETAILS'
export const FETCH_TOTAL_SPENT_DETAILS_SUCCESS = 'FETCH_TOTAL_SPENT_DETAILS_SUCCESS'
export const FETCH_TOTAL_SPENT_DETAILS_FAILURE = 'FETCH_TOTAL_SPENT_DETAILS_FAILURE'

export const FETCH_AVERAGE_SPENT_DETAILS = 'FETCH_AVERAGE_SPENT_DETAILS'
export const FETCH_AVERAGE_SPENT_DETAILS_SUCCESS = 'FETCH_AVERAGE_SPENT_DETAILS_SUCCESS'
export const FETCH_AVERAGE_SPENT_DETAILS_FAILURE = 'FETCH_AVERAGE_SPENT_DETAILS_FAILURE'

export const fetchTotalReceiptsDetails = ({ fromDate, toDate, selectedCategories }) => ({
    [CALL_API]: {
        types: [FETCH_TOTAL_RECEIPTS_DETAILS, FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS, FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE],
        endpoint: `/dashboard/basicStatsDetails?type=TOTAL_RECEIPTS&from_date=${fromDate}&to_date=${toDate}&categories=${selectedCategories.join(',')}`,
        options: {
            method: 'GET'
        }
    }
})

export const fetchTotalItemsDetails= ({ fromDate, toDate, selectedCategories }) => ({
    [CALL_API]: {
        types: [FETCH_TOTAL_ITEMS_DETAILS, FETCH_TOTAL_ITEMS_DETAILS_SUCCESS, FETCH_TOTAL_ITEMS_DETAILS_FAILURE],
        endpoint: `/dashboard/basicStatsDetails?type=TOTAL_ITEMS&from_date=${fromDate}&to_date=${toDate}&categories=${selectedCategories.join(',')}`,
        options: {
            method: 'GET'
        }
    }
})

export const fetchAverageBasketDetails = ({ fromDate, toDate, selectedCategories }) => ({
    [CALL_API]: {
        types: [FETCH_AVERAGE_BASKET_DETAILS, FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS, FETCH_AVERAGE_BASKET_DETAILS_FAILURE],
        endpoint: `/dashboard/basicStatsDetails?type=AVERAGE_BASKET&from_date=${fromDate}&to_date=${toDate}&categories=${selectedCategories.join(',')}`,
        options: {
            method: 'GET'
        }
    }
})


export const fetchTotalSpentDetails = ({ fromDate, toDate, selectedCategories }) => ({
    [CALL_API]: {
        types: [FETCH_TOTAL_SPENT_DETAILS, FETCH_TOTAL_SPENT_DETAILS_SUCCESS, FETCH_TOTAL_SPENT_DETAILS_FAILURE],
        endpoint: `/dashboard/basicStatsDetails?type=TOTAL_SPENT&from_date=${fromDate}&to_date=${toDate}&categories=${selectedCategories.join(',')}`,
        options: {
            method: 'GET'
        }
    }
})
export const fetchAverageSpentDetails = ({ fromDate, toDate, selectedCategories }) => ({
    [CALL_API]: {
        types: [FETCH_AVERAGE_SPENT_DETAILS, FETCH_AVERAGE_SPENT_DETAILS_SUCCESS, FETCH_AVERAGE_SPENT_DETAILS_FAILURE],
        endpoint: `/dashboard/basicStatsDetails?type=AVERAGE_SPENT&from_date=${fromDate}&to_date=${toDate}&categories=${selectedCategories.join(',')}`,
        options: {
            method: 'GET'
        }
    }
})