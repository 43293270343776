import { combineReducers } from 'redux'
import * as ActionTypes from '../../actions/support'

const isSendingSupportQuery = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SEND_SUPPORT_QUERY:
      return true

    case ActionTypes.SEND_SUPPORT_QUERY_SUCCESS:
    case ActionTypes.SEND_SUPPORT_QUERY_FAILURE:
      return false

    default:
      return state
  }
}
const isSupportQuerySent = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.SEND_SUPPORT_QUERY_SUCCESS:
      return true

    case ActionTypes.SEND_SUPPORT_QUERY_FAILURE:
      return false
    default:
      return state
  }
}


export const support = combineReducers({
  isSendingSupportQuery,
  isSupportQuerySent
})