import { combineReducers } from "redux";

import { dashboard } from "./dashboard";
import { filters } from "./filters";
import { user } from "./user";
import { support } from "./support";
import { merchants } from "./merchants";
import { merchantDetails } from "./merchant-details";
import { receipts } from "./receipts";
export default combineReducers({
  dashboard,
  receipts,
  filters,
  user,
  support,
  merchants,
  merchantDetails
});
