import moment from "moment";
import * as ActionTypes from "../../actions/merchants/filters";

const initialState = {
  // fromDate: moment()
  //   .subtract(7, "days")
  //   .format("YYYY-MM-DD"),
  // toDate: moment().format("YYYY-MM-DD"),
  limit: {
    value: 10,
    label: "10"
  },
  offset: 0,
  searchTerm: "",
  visible: false,
  merchantCategories: [],
  sort_by: "revenue",
  sort_order: "DESC"
};

const {
  SET_LIMIT,
  // SET_MERCHANT_LIST_DATERANGE,
  // SET_MERCHANT_LIST_FROM_DATE,
  // SET_MERCHANT_LIST_TO_DATE,
  SET_MERCHANT_LIST_FILTERS,
  SET_OFFSET,
  SET_STORE,
  RESET_FILTERS,
  TOGGLE_MERCHANT_LIST_FILTER_VISIBILITY,
  TOGGLE_MERCHANT_LIST_CATEGORY,
  TOGGLE_MERCHANT_LIST_CATEGORIES_ALL,
  SORT_MERCHANT_LIST_BY,
  SORT_MERCHANT_LIST_ORDER
} = ActionTypes;
const merchantListFilters = (state = initialState, action) => {
  switch (action.type) {
    // case SET_MERCHANT_LIST_TO_DATE:
    //   return { ...state, toDate: action.payload };

    // case SET_MERCHANT_LIST_FROM_DATE:
    //   return { ...state, fromDate: action.payload };

    // case SET_MERCHANT_LIST_DATERANGE:
    //   return {
    //     ...state,
    //     fromDate: action.payload.fromDate,
    //     toDate: action.payload.toDate
    //   };
    case SET_MERCHANT_LIST_FILTERS:
      return { ...state, ...action.payload };

    case SET_LIMIT:
      return { ...state, limit: { ...action.payload } };

    case SET_OFFSET:
      return { ...state, offset: action.payload };

    case SET_STORE:
      return {
        ...state,
        store_name: action.payload
      };
    case RESET_FILTERS:
      return { ...initialState, ...action.payload };

    case TOGGLE_MERCHANT_LIST_FILTER_VISIBILITY:
      return { ...state, visible: !state.visible };

    case TOGGLE_MERCHANT_LIST_CATEGORY: {
      // ====================================================
      //  FOR SELECTION OF ONLY SINGLE CATEGORY AT A TIME
      // ====================================================
      const { merchantCategories } = state;
      const index = merchantCategories.indexOf(action.payload);
      if (index === -1) {
        return {
          ...state,
          merchantCategories: [action.payload]
        };
      } else {
        const newSelectedCategories = [...state.merchantCategories];
        newSelectedCategories.splice(index, 1);
        return {
          ...state,
          merchantCategories: newSelectedCategories
        };
      }
      // ====================================================
      //  FOR SELECTION OF MULITPLE CATEGORIES AT A TIME
      // ====================================================
      // const { selectedCategories } = state;
      // if (selectedCategories.indexOf(action.payload) === -1) {
      //   return {
      //     ...state,
      //     selectedCategories: [...state.selectedCategories, action.payload]
      //   };
      // } else {
      //   return {
      //     ...state,
      //     selectedCategories: [...state.selectedCategories].filter(
      //       item => item !== action.payload
      //     )
      //   };
      // }
    }
    case TOGGLE_MERCHANT_LIST_CATEGORIES_ALL:
      return { ...state, merchantCategories: action.payload };

    case SORT_MERCHANT_LIST_BY:
      return {
        ...state,
        sort_by: action.payload
      };
    case SORT_MERCHANT_LIST_ORDER:
      return {
        ...state,
        sort_order: action.payload
      };
    default:
      return state;
  }
};

export { merchantListFilters };
