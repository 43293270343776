import React, { memo } from "react";
import { connect } from "react-redux";

import "./App.css";
import BaseContainer from "./components/BaseContainer";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import MerchantsList from "./views/Merchants/";
import MerchantDetail from "./views/Merchants/MerchantDetail";
// import CustomerList from './views/Customers/CustomerList';
import ReceiptsList from "./views/Receipts/ReceiptsList";
import ReceiptDetails from "./views/Receipts/ReceiptDetails";
import Login from "./views/Login";
import Support from "./views/Support";
import Dashboard from "./views/Dashboard";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";

toast.configure();
// =====================================================
const App = () => (
  <div className="App">
    <Router>
      <BaseContainer>
        <div>
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/support" exact component={Support} />
          <PrivateRoute
            path="/merchants/:merchantName"
            component={MerchantDetail}
          />
          <PrivateRoute exact path="/merchants" component={MerchantsList} />
          <PrivateRoute
            exact
            path="/receipts/:clientId/:receiptId"
            component={ReceiptDetails}
          />
          <PrivateRoute exact path="/receipts" component={ReceiptsList} />
        </div>
      </BaseContainer>

      <Route path="/login" exact component={Login} />
    </Router>
  </div>
);
// =====================================================
const tempPrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        user !== null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
const mapStateToProps = state => ({ user: state.user.user });
const PrivateRoute = memo(connect(mapStateToProps)(tempPrivateRoute));
// =====================================================
export default memo(App);
