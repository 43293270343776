export const SET_RECEIPT_LIST_FROM_DATE = "SET_RECEIPT_LIST_FROM_DATE";
export const SET_RECEIPT_LIST_TO_DATE = "SET_RECEIPT_LIST_TO_DATE";
export const SET_RECEIPT_LIST_MULTIPLE_CATEGORIES =
  "SET_RECEIPT_LIST_MULTIPLE_CATEGORIES";
export const SET_RECEIPT_LIST_DATERANGE = "SET_RECEIPT_LIST_DATERANGE";
export const SET_RECEIPT_LIST_FILTERS = "SET_RECEIPT_LIST_FILTERS";
export const SET_RECEIPT_LIST_LIMIT = "SET_RECEIPT_LIST_LIMIT";
export const SET_RECEIPT_LIST_OFFSET = "SET_RECEIPT_LIST_OFFSET";
export const SET_RECEIPT_LIST_STORE = "SET_RECEIPT_LIST_STORE";

export const RESET_RECEIPT_LIST_FILTERS = "RESET_RECEIPT_LIST_FILTERS";

export const TOGGLE_RECEIPT_LIST_FILTER_VISIBILITY =
  "TOGGLE_RECEIPT_LIST_FILTER_VISIBILITY";
export const TOGGLE_RECEIPT_LIST_CATEGORY = "TOGGLE_RECEIPT_LIST_CATEGORY";
export const TOGGLE_RECEIPT_LIST_CATEGORIES_ALL =
  "TOGGLE_RECEIPT_LIST_CATEGORIES_ALL";
export const SORT_RECEIPT_LIST_ORDER = "SORT_RECEIPT_LIST_ORDER";
export const SORT_RECEIPT_LIST_BY = "SORT_RECEIPT_LIST_BY";

// ========================================================================
export const setReceiptListFromDate = fromDate => ({
  type: SET_RECEIPT_LIST_FROM_DATE,
  payload: fromDate
});

export const setReceiptListToDate = toDate => ({
  type: SET_RECEIPT_LIST_TO_DATE,
  payload: toDate
});

export const setReceiptListDateRange = (fromDate, toDate) => ({
  type: SET_RECEIPT_LIST_DATERANGE,
  payload: { fromDate, toDate }
});

export const setReceiptListFilters = filters => ({
  type: SET_RECEIPT_LIST_FILTERS,
  payload: filters
});

export const resetReceiptListFilters = filters => ({
  type: RESET_RECEIPT_LIST_FILTERS,
  payload: filters
});

export const setReceiptListLimit = limit => ({
  type: SET_RECEIPT_LIST_LIMIT,
  payload: limit
});

export const setReceiptListOffset = offset => ({
  type: SET_RECEIPT_LIST_OFFSET,
  payload: offset
});

export const setReceiptListStore = store_name => ({
  type: SET_RECEIPT_LIST_STORE,
  payload: store_name
});

export const setReceiptListsortBy = sortBy => ({
  type: SORT_RECEIPT_LIST_BY,
  payload: sortBy
});

export const setReceiptListSortOrder = sortOrder => ({
  type: SORT_RECEIPT_LIST_ORDER,
  payload: sortOrder
});

export const toggleReceiptListCategoriesAll = payload => ({
  type: TOGGLE_RECEIPT_LIST_CATEGORIES_ALL,
  payload
});

export const toggleReceiptListFilterVisibility = () => ({
  type: TOGGLE_RECEIPT_LIST_FILTER_VISIBILITY
});

export const toggleReceiptListCategory = payload => ({
  type: TOGGLE_RECEIPT_LIST_CATEGORY,
  payload
});
