import { CALL_API } from "../../apiMiddleware";
export const FETCH_RECENT_RECEIPTS_MERCHANTS =
  "FETCH_RECENT_RECEIPTS_MERCHANTS";
export const FETCH_RECENT_RECEIPTS_MERCHANTS_SUCCESS =
  "FETCH_RECENT_RECEIPTS_MERCHANTS_SUCCESS";
export const FETCH_RECENT_RECEIPTS_MERCHANTS_FAILURE =
  "FETCH_RECENT_RECEIPTS_MERCHANTS_FAILURE";
// ======================================================
const baseUrl = "/merchants/recent-receipts";
// ======================================================

export const fetchRecentReceipts = ({ store_name }) => ({
  [CALL_API]: {
    types: [
      FETCH_RECENT_RECEIPTS_MERCHANTS,
      FETCH_RECENT_RECEIPTS_MERCHANTS_SUCCESS,
      FETCH_RECENT_RECEIPTS_MERCHANTS_FAILURE
    ],
    // endpoint: `${baseUrl}/rolex`,
    endpoint: `${baseUrl}/${store_name}`,
    options: {
      method: "GET"
    }
  }
});
// ======================================================
