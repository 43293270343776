import { humanizeCurrency } from "../../../utils";
import { chunk } from "lodash";
export const getTotalSpentDataSet = chart => {
  const categories = chart && chart.length ? chart.map(c => c.category) : [];
  const spents =
    chart && chart.length
      ? chart.map(c =>
          humanizeCurrency({ amount: c.total_spent, type: "number" })
        )
      : [];
  return [
    {
      columns: ["Categories"],
      data: categories.length ? [[""], ...chunk(categories, 1)] : []
    },
    {
      xSteps: 3,
      ySteps: -(categories.length + 2),
      columns: ["Amount"],
      data: spents.length ? [[""], ...chunk(spents, 1)] : []
    }
  ];
};
