import React from "react";
import Loader from "react-loader-spinner";

const EmaarLoader = ({ height = 25, width = 25 }) => {
  return (
    <Loader type="ThreeDots" color="#333333" height={height} width={width} />
  );
};

export default EmaarLoader;
