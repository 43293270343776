// =======================================================================
export const makeReceiptListQueryString = (receiptListFilters,filters) => {
         const { fromDate, toDate } = filters;
         const {
           limit,
           offset,
           store_name = "",
           receiptCategories = [],
           sort_by = "date",
           sort_order = "DESC"
         } = receiptListFilters;
         let category = receiptCategories[0] || "";
         if (receiptCategories.length > 1) {
           category = "";
         }
         return `limit=${limit.value}&offset=${offset}&fromDate=${fromDate}&toDate=${toDate}&category=${category}&store_name=${store_name}&sort_by=${sort_by}&sort_order=${sort_order}`;
       };

// =======================================================================
export const parseReceiptListQueryString = qs => {
  const str = qs.substring(1, qs.length);
  const paramArray = str.split("&");
  const r = {};
  paramArray.forEach(param => {
    const [key, value] = param.split("=");
    if (key === "limit") {
      r[key] = { label: value, value };
    } else if (key === "category") {
      r["selectedCategories"] = [value];
    } else {
      r[key] = value;
    }
  });
  return r;
};
// =======================================================================
