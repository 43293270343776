import { CALL_API } from "../../apiMiddleware";

export const FETCH_TOTAL_RECEIPTS_DETAILS = "FETCH_TOTAL_RECEIPTS_DETAILS";
export const FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS =
  "FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS";
export const FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE =
  "FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE";

export const FETCH_TOTAL_ITEMS_DETAILS = "FETCH_TOTAL_ITEMS_DETAILS";
export const FETCH_TOTAL_ITEMS_DETAILS_SUCCESS =
  "FETCH_TOTAL_ITEMS_DETAILS_SUCCESS";
export const FETCH_TOTAL_ITEMS_DETAILS_FAILURE =
  "FETCH_TOTAL_ITEMS_DETAILS_FAILURE";

export const FETCH_AVERAGE_BASKET_DETAILS = "FETCH_AVERAGE_BASKET_DETAILS";
export const FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS =
  "FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS";
export const FETCH_AVERAGE_BASKET_DETAILS_FAILURE =
  "FETCH_AVERAGE_BASKET_DETAILS_FAILURE";

export const FETCH_TOTAL_SPENT_DETAILS = "FETCH_TOTAL_SPENT_DETAILS";
export const FETCH_TOTAL_SPENT_DETAILS_SUCCESS =
  "FETCH_TOTAL_SPENT_DETAILS_SUCCESS";
export const FETCH_TOTAL_SPENT_DETAILS_FAILURE =
  "FETCH_TOTAL_SPENT_DETAILS_FAILURE";

export const FETCH_AVERAGE_SPENT_DETAILS = "FETCH_AVERAGE_SPENT_DETAILS";
export const FETCH_AVERAGE_SPENT_DETAILS_SUCCESS =
  "FETCH_AVERAGE_SPENT_DETAILS_SUCCESS";
export const FETCH_AVERAGE_SPENT_DETAILS_FAILURE =
  "FETCH_AVERAGE_SPENT_DETAILS_FAILURE";
// ======================================================
const baseUrl = "/merchants/basic-stats";
// ======================================================

export const fetchTotalReceiptsDetails = ({
  fromDate,
  toDate,
  store_name
}) => ({
  [CALL_API]: {
    types: [
      FETCH_TOTAL_RECEIPTS_DETAILS,
      FETCH_TOTAL_RECEIPTS_DETAILS_SUCCESS,
      FETCH_TOTAL_RECEIPTS_DETAILS_FAILURE
    ],
    endpoint: `${baseUrl}/TOTAL_RECEIPTS/${store_name}?from_date=${fromDate}&to_date=${toDate}`,
    options: {
      method: "GET"
    }
  }
});
// ======================================================

export const fetchTotalItemsDetails = ({ fromDate, toDate, store_name }) => ({
  [CALL_API]: {
    types: [
      FETCH_TOTAL_ITEMS_DETAILS,
      FETCH_TOTAL_ITEMS_DETAILS_SUCCESS,
      FETCH_TOTAL_ITEMS_DETAILS_FAILURE
    ],
    endpoint: `${baseUrl}/TOTAL_ITEMS/${store_name}?from_date=${fromDate}&to_date=${toDate}`,
    options: {
      method: "GET"
    }
  }
});
// ======================================================

export const fetchAverageBasketDetails = ({
  fromDate,
  toDate,
  store_name
}) => ({
  [CALL_API]: {
    types: [
      FETCH_AVERAGE_BASKET_DETAILS,
      FETCH_AVERAGE_BASKET_DETAILS_SUCCCESS,
      FETCH_AVERAGE_BASKET_DETAILS_FAILURE
    ],
    endpoint: `${baseUrl}/AVERAGE_BASKET/${store_name}?from_date=${fromDate}&to_date=${toDate}`,
    options: {
      method: "GET"
    }
  }
});
// ======================================================

export const fetchTotalSpentDetails = ({ fromDate, toDate, store_name }) => ({
  [CALL_API]: {
    types: [
      FETCH_TOTAL_SPENT_DETAILS,
      FETCH_TOTAL_SPENT_DETAILS_SUCCESS,
      FETCH_TOTAL_SPENT_DETAILS_FAILURE
    ],
    endpoint: `${baseUrl}/TOTAL_SPENT/${store_name}?from_date=${fromDate}&to_date=${toDate}`,
    options: {
      method: "GET"
    }
  }
});
// ======================================================

export const fetchAverageSpentDetails = ({
  fromDate,
  toDate,
  store_name = "rolex"
}) => ({
  [CALL_API]: {
    types: [
      FETCH_AVERAGE_SPENT_DETAILS,
      FETCH_AVERAGE_SPENT_DETAILS_SUCCESS,
      FETCH_AVERAGE_SPENT_DETAILS_FAILURE
    ],
    endpoint: `${baseUrl}/AVERAGE_SPENT/${store_name}?from_date=${fromDate}&to_date=${toDate}`,
    options: {
      method: "GET"
    }
  }
});
// ======================================================
