export const customStyles = {
  content: {
    top: "10%",
    left: "20%",
    width: "60%",
    right: "20%",
    maxHeight: "100"
  }
};

export const contentStyles = {
  fontSize: "15px",
  borderRadius: "5px",
  padding: "5px"
};

export const groupCategories = data => {
  const fixedCategories = 20;
  const sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.total_receipts < b.total_receipts) {
      return 1;
    } else {
      return -1;
    }
  });
  let others = 0;
  const newData = sortedData.map((c, i) => {
    if (i <= fixedCategories) {
      return c;
    }
    others += Number(c.total_receipts);
    return c;
  });
  newData.splice(fixedCategories);
  newData.push({
    total_receipts: others,
    brand_category: "Other"
  });
  const sum = newData.reduce((sum, item) => (sum += item.total_receipts), 0);
  const groupedData = newData.reduce((r, item) => {
    if (item.total_receipts / sum <= 0.01) {
      r.push({ ...item, brand_category: "Other" });
    } else {
      r.push(item);
    }

    r = r.reduce((arr, item) => {
      if (item.brand_category === "Other") {
        if (arr.filter(itm => itm.brand_category === "Other")[0]) {
          arr = arr.map(i =>
            i.brand_category === "Other"
              ? {
                  ...i,
                  total_receipts: i.total_receipts + item.total_receipts
                }
              : i
          );
        } else {
          arr.push(item);
        }
      } else {
        arr.push(item);
      }
      return arr;
    }, []);

    return r;
  }, []);

  return groupedData;
};
