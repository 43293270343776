import React from "react";

const index = () => {
  return (
    <div
      style={{
        width: "320px",
        display: "flex",
        height: "250px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <h1>No Data</h1>
    </div>
  );
};

export default index;
