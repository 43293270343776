import * as ActionTypes from '../../actions/dashboard'

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
}

export const categories = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_CATEGORIES:
      return { ...state, isLoading: true }

    case ActionTypes.FETCH_ALL_CATEGORIES_SUCCESS:
      return { ...state, isLoading: false, isDataAvailable: true, data: action.payload.data.map(item => encodeURIComponent(item)) }

    case ActionTypes.FETCH_ALL_CATEGORIES_FAILURE:
      return { ...state, isLoading: false, isDataAvailable: false }

    default:
      return state
  }
}