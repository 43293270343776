import { combineReducers } from "redux";
import { merchantDetailsFilters } from "./filters";
import { basicStats } from "./basicStats";
import { salesFigures } from "./salesFigures";
import { recentReceipts } from "./recent-receipts";

export const merchantDetails = combineReducers({
  merchantDetailsFilters,
  basicStats,
  salesFigures,
  recentReceipts
});
