import React, { memo } from "react";
import Moment from "moment";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar
} from "recharts";
import { humanizeCurrency } from "../../utils";
import { transFormReport, renderLegend, getNewCategories } from "./utils";
import tooltipStyles from "../tooltipStyles";

const SalesReportChart = ({
  report,
  normalizedReport,
  receiptsCategories,
  colors
}) => {
  let fixedCategories = 19;
  const newCategories = getNewCategories(receiptsCategories, fixedCategories);
  const newNormalizedReport = transFormReport(normalizedReport);
  const newReport = transFormReport(report);

  // =================================================================================
  const getRawSalesReport = (value, name, props) => {
    const {
      payload: { month }
    } = props;

    const actualValue = newReport.filter(item => item.month === month)[0][name];
    const percentageValue =
      newNormalizedReport.filter(item => item.month === month)[0][name] || "";
    // console.log("Percentage Value: ", percentageValue);
    if (percentageValue) {
      const formatValue = ` ${percentageValue}% (${humanizeCurrency({
        amount: actualValue,
        type: "currency",
        chart: "sales_report"
      })})`;
      // const formatValue = humanizeCurrency({amount})
      return [formatValue, name];
    } else {
      return [];
    }
  };
  // =================================================================================

  const generateYAxisLabels = label => {
    if (report === normalizedReport) {
      return `${label}%`;
    }
    return humanizeCurrency({
      amount: label,
      type: "currency",
      chart: "sales_report"
    });
  };
  // =================================================================================

  return (
    <>
      {newReport && (
        <div className="graph-area">
          <ResponsiveContainer width={"100%"} height={430}>
            <BarChart barCategoryGap="30%" barGap={1} data={newReport}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={e => Moment(e.month, "MM").format("MMM")}
                height={50}
              />
              <YAxis
                tickFormatter={generateYAxisLabels}
                scale="auto"
                width={100}
              />
              <Tooltip
                contentStyle={tooltipStyles.content}
                itemStyle={tooltipStyles.item}
                labelStyle={tooltipStyles.label}
                labelFormatter={label =>
                  Moment()
                    .month(label, "MMM")
                    .format("MMMM")
                }
                formatter={getRawSalesReport}
                cursor={{
                  fill: "none"
                }}
                hello="hello"
              />
              <Legend
                verticalAlign="bottom"
                align="left"
                content={renderLegend}
                height={30}
                colors={colors}
              />
              {/* <Legend verticalAlign="bottom" align="left" height={25} /> */}
              {newCategories &&
                newCategories.map((category, index) => {
                  return (
                    <Bar
                      key={category || index}
                      legendType="circle"
                      dataKey={category}
                      barSize={25}
                      stackId="a"
                      fill={colors[category]}
                    />
                  );
                })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default memo(SalesReportChart);
