import { combineReducers } from "redux";
import { receiptsList } from "./receipts-list";
import { receiptDetails } from "./receipt-details";
import { receiptListFilters } from "./filters";
import { categories } from "./categories";

export const receipts = combineReducers({
  receiptsList,
  receiptListFilters,
  receiptDetails,
  categories
});
