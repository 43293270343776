import React from "react";
const CustomInput = React.forwardRef(
  ({ dateDiff, active, value, onClick }, ref) => {
    const dateCheck =
      dateDiff !== 1 &&
      dateDiff !== 7 &&
      dateDiff !== 30 &&
      dateDiff !== 90 &&
      dateDiff !== 0;

    return (
      <input
        ref={ref}
        readOnly={true}
        onClick={onClick}
        value={value}
        className={`custom-input-dp ${dateCheck ? active : " "}`}
      />
    );
  }
);

export default CustomInput;
