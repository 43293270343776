import React, { useState, memo } from "react";
import { humanizeCurrency } from "../../../utils";
// =====================================================
export default memo(({ name, price, quantity }) => {
  const [arrow, changeArrow] = useState(true);
  return (
    <li className="list-item" onClick={() => changeArrow(!arrow)}>
      <span className="title">{name || "Description: N/A"}</span>
      <span className="value">
        {humanizeCurrency({
          amount: price * quantity,
          type: "currency"
        })}
        {arrow ? (
          <i class="fa fa-caret-down"></i>
        ) : (
          <i class="fa fa-caret-up"></i>
        )}
      </span>
    </li>
  );
});
// =====================================================
