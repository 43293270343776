import React, { useEffect, memo } from "react";
import { connect } from "react-redux";
import { omit } from "lodash";
import Home from "./Home";
import EmaarLoader from "../../components/EmaarLoader";
import { fetchAllCategories } from "../../actions/dashboard/index";

const HomeContainer = props => {
  const styles = {
    width: "100%",
    height: "500px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };
  const { dispatch, isDataAvailable, isLoading } = props;
  useEffect(() => {
    if (!isLoading && !isDataAvailable) {
      dispatch(fetchAllCategories());
    }
  }, []);
  return (
    <>
      {isLoading && (
        <div style={styles}>
          <EmaarLoader height={80} width={80} />
        </div>
      )}
      {!isLoading && isDataAvailable && (
        <Home {...omit(props, ["isLoading", "isDataAvailable", "data"])} />
      )}
    </>
  );
};

const mapStateToProps = state => ({ ...state.dashboard.categories });
export default memo(connect(mapStateToProps)(HomeContainer));
