import {  CALL_API } from '../../apiMiddleware'

export const SEND_SUPPORT_QUERY = 'SEND_SUPPORT_QUERY'
export const SEND_SUPPORT_QUERY_SUCCESS = 'SEND_SUPPORT_QUERY_SUCCESS'
export const SEND_SUPPORT_QUERY_FAILURE = 'SEND_SUPPORT_QUERY_FAILURE'

export const sendSupportQuery = (query) => ({
  [CALL_API]: {
    types: [SEND_SUPPORT_QUERY, SEND_SUPPORT_QUERY_SUCCESS, SEND_SUPPORT_QUERY_FAILURE],
    endpoint: `/support`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(query)
    }
  }
})