import React, { Fragment, memo } from "react";
import SalesReportChart from "../SalesReportChart";
import { normalize_sales_report_data } from "./utils";
const ContributionChart = memo(
  ({ salesReport, categories, receiptsCategories, colors }) => {
    const normalizedSalesReport = normalize_sales_report_data(salesReport);
    return (
      <Fragment>
        {normalizedSalesReport && (
          <SalesReportChart
            receiptsCategories={receiptsCategories}
            report={salesReport}
            normalizedReport={normalizedSalesReport}
            rawReport={salesReport}
            categories={categories}
            colors={colors}
          />
        )}
      </Fragment>
    );
  }
);

export default ContributionChart;
