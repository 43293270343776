import React from "react";
import Select from "react-select";
const numRecordsOptions = [
  { value: 10, label: "10" },
  { value: 50, label: "50" },
  { value: 100, label: "100" }
];
const Pagination = ({
  handleChangeOffset,
  limit,
  limit: { value },
  total_pages,
  pageNeighbours,
  pagination_count,
  handleLimitChange,
  offset
}) => {
  // ========================================================
  const handlePageClick = pageNum => {
    if (pageNum >= 0 && pageNum <= total_pages) {
      handleChangeOffset((pageNum - 1) * value);
    }
  };
  // ========================================================
  const handlePreviousClick = offset => {
    if (offset > 0) {
      handleChangeOffset(offset - value);
    }
  };
  // ========================================================
  const handleNextClick = offset => {
    if (offset + Number(value) < pagination_count) {
      handleChangeOffset(Number(offset) + Number(value));
    }
  };
  // ========================================================
  let pageNumbers = [];
  let startPage = Math.floor(offset / limit.value) - pageNeighbours;
  let lastPage = Math.floor(offset / limit.value) + pageNeighbours;

  if (total_pages === 1) {
    pageNumbers = [
      <li key={1} className={"active"}>
        <a href="#/" onClick={() => handlePageClick(1)}>
          {1}
        </a>
      </li>
    ];
  } else {
    if (lastPage >= 7 && startPage !== 1) {
      pageNumbers.push(
        <li
          key={1}
          className={Math.ceil(offset / limit.value) === 1 ? "active" : ""}
        >
          <a href="#/" onClick={() => handlePageClick(1)}>
            {1}
          </a>
        </li>
      );
    }
    for (let i = startPage; i < lastPage; i++) {
      if (i >= 1 && i < total_pages) {
        pageNumbers.push(
          <li
            key={i}
            className={
              Math.ceil(offset / limit.value) + 1 === i ? "active" : ""
            }
          >
            <a href="#/" onClick={() => handlePageClick(i)}>
              {i}
            </a>
          </li>
        );
      }
    }
    if (total_pages > 3) {
      pageNumbers = [
        ...pageNumbers,
        <li>
          <a href="#/">...</a>
        </li>
      ];
    }
    if (total_pages > 1) {
      pageNumbers = [
        ...pageNumbers,
        <li
          key={total_pages}
          className={
            Math.ceil(offset / limit.value) === total_pages - 1 ? "active" : ""
          }
        >
          <a href="#/" onClick={() => handlePageClick(total_pages)}>
            {total_pages}
          </a>
        </li>
      ];
    }
  }
  let firstItem = 0;
  let lastItem = 0;

  if (pagination_count > 10) {
    firstItem = Number(offset) + 1;
    lastItem = Math.min(
      Number(offset) + Number(limit.value),
      Number(pagination_count)
    );
  }
  // ========================================================
  return (
    <div className="paging-holder">
      <span className="number-item">
        {firstItem > 0
          ? `${firstItem} - ${lastItem} of ${pagination_count} items`
          : ""}
      </span>

      {firstItem > 0 ? (
        <div className="paging-right">
          <ul className="paging">
            <li className="back">
              <a
                href="#/"
                onClick={() => {
                  handlePreviousClick(offset);
                }}
              >
                <i className="icon-back"></i>
              </a>
            </li>
            {pageNumbers}
            <li className="next" onClick={() => handleNextClick(offset)}>
              <a href="#/">
                <i className="icon-back"></i>
              </a>
            </li>
          </ul>
          <div className="select-holder">
            <Select
              classNamePrefix={"select"}
              value={limit}
              onChange={handleLimitChange}
              options={numRecordsOptions}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
// ========================================================
export default Pagination;
