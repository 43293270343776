import React from "react";

const TableHeader = ({ headers, handleSortBy, sort_order, sort_by }) => (
  <>
    {headers.map((header, i) => (
      <th
        className="emaar-table-header"
        key={i}
        onClick={
          header.value !== "category"
            ? () => handleSortBy(header.value)
            : () => {}
        }
      >
        <span>{header.label}</span>
        <span
          style={{
            fontSize: "15px",
            margin: "3px",
            paddingTop: "3px",
            alignItems: "center"
          }}
        >
          {header.value !== "category" ? (
            <i
              className={`fas fa-caret-${
                sort_order === "DESC" && sort_by === header.value
                  ? "down"
                  : "up"
              }`}
            ></i>
          ) : null}
        </span>
      </th>
    ))}

    <th> </th>
  </>
);

export default TableHeader;
