import React, { useState, memo } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import PieChartModal from "./PieChartModal";
import { renderCustomizedlabel } from "./renderCustomizedlabel";
import { humanizeCurrency } from "../../utils";
import { groupCategories, contentStyles } from "./utils";
import { isEmpty } from "lodash";
// ===========================================================
export default memo(({ data, colors }) => {
  const [showModal, setshowModal] = useState(false);
  const chartData = !isEmpty(data) ? groupCategories(data) : [];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <PieChartModal
        data={chartData}
        colors={colors}
        showModal={showModal}
        closeModal={() => setshowModal(false)}
      />
      <PieChart width={500} height={550}>
        <Pie
          innerRadius={140}
          outerRadius={200}
          dataKey={"total_receipts"}
          nameKey={"brand_category"}
          isAnimationActive={false}
          data={chartData}
          cx={"50%"}
          cy={"42%"}
          fill="#8884d8"
          // labelLine={false}
          label={renderCustomizedlabel}
        >
          {chartData &&
            chartData.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[entry.brand_category]}
                  onClick={() => setshowModal(true)}
                />
              );
            })}
        </Pie>
        <Tooltip
          contentStyles={contentStyles}
          formatter={value =>
            humanizeCurrency({ amount: value, type: "number" })
          }
        />
      </PieChart>
    </div>
  );
});
