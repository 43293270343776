import React, { useEffect, memo } from "react";
import { connect } from "react-redux";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import ReceiptListTable from "./ReceiptListTable";
import TopRowFilters from "../../components/TopRowFilters";
import Moment from "moment";
import {
  fetchReceiptsList,
  fetchReceiptDetails,
  fetchAllCategories
} from "../../actions/receipts";
import {
  setReceiptListFilters,
  setReceiptListStore,
  // setReceiptListFromDate,
  // setReceiptListToDate,
  setReceiptListLimit,
  // setReceiptListDateRange,
  setReceiptListOffset,
  setReceiptListSortOrder,
  setReceiptListsortBy
} from "../../actions/receipts/filters";
import {
  setToDate,
  setDateRange,
  setFromDate,
  setFilters
} from "../../actions/dashboard/filters";
import {
  makeReceiptListQueryString,
  parseReceiptListQueryString
} from "../../utils";
//  ================================================
const ReceiptList = props => {
  const {
    dispatch,
    filters,
    location: { search },
    receiptListFilters,
    receiptListFilters: { sort_order, sort_by },
    receiptsList,
    receiptsList: { data, isDataAvailable },
    history: { push }
  } = props;
  //  ================================================
  const handleSortBy = sort_by => {
    const { sort_order } = receiptListFilters;
    const newSortOrder = sort_order === "DESC" ? "ASC" : "DESC";
    const qs = makeReceiptListQueryString(
      {
        ...receiptListFilters,
        sort_by,
        sort_order: newSortOrder
      },
      filters
    );
    push(`/receipts?${qs}`);
    dispatch(setReceiptListsortBy(sort_by));
    dispatch(setReceiptListSortOrder(newSortOrder));
    dispatch(
      fetchReceiptsList({
        ...receiptListFilters,
        ...filters,
        sort_by,
        sort_order: newSortOrder
      })
    );
  };
  //  ================================================
  const onSelectReceipt = ({ request_id, client_id }) => {
    dispatch(fetchReceiptDetails(request_id, client_id));
    push(`/receipts/${client_id}/${request_id}`);
  };
  //  ================================================
  const handleChangeStartDate = date => {
    const fromDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeReceiptListQueryString(receiptListFilters, {
      ...filters,
      fromDate
    });
    push(`/receipts?${qs}`);
    dispatch(setFromDate(fromDate));
    dispatch(
      fetchReceiptsList({ ...receiptListFilters, ...filters, fromDate })
    );
  };
  //  ================================================
  const handleChangeEndDate = date => {
    const toDate = Moment(date).format("YYYY-MM-DD");
    const qs = makeReceiptListQueryString(receiptListFilters,{
      ...filters,
      toDate
    });
    push(`/receipts?${qs}`);
    dispatch(setToDate(toDate));
    dispatch(fetchReceiptsList({ ...receiptListFilters, ...filters, toDate }));
  };
  //  ================================================
  const handleDateListClick = numDays => {
    let fromDate = Moment("01/01/2016", "DD/MM/YYYY").format("YYYY-MM-DD");
    if (numDays !== -1) {
      fromDate = Moment()
        .subtract(numDays, "days")
        .format("YYYY-MM-DD");
    }

    const toDate = Moment().format("YYYY-MM-DD");
    const qs = makeReceiptListQueryString(receiptListFilters, {
      ...filters,
      fromDate,
      toDate
    });
    push(`/receipts?${qs}`);
    dispatch(setDateRange(fromDate, toDate));
    dispatch(
      fetchReceiptsList({ ...receiptListFilters, ...filters, fromDate, toDate })
    );
  };
  //  ================================================
  const handleLimitChange = limit => {
    dispatch(setReceiptListLimit(limit));
    dispatch(setReceiptListOffset(0));
    const qs = makeReceiptListQueryString(
      {
        ...receiptListFilters,
        limit,
        offset: 0
      },
      filters
    );
    push(`/receipts?${qs}`);
    dispatch(
      fetchReceiptsList({ ...receiptListFilters, ...filters, limit, offset: 0 })
    );
  };
  //  ================================================
  const handleChangeOffset = offset => {
    dispatch(setReceiptListOffset(offset));
    const qs = makeReceiptListQueryString(
      { ...receiptListFilters, offset },
      filters
    );
    push(`/receipts?${qs}`);
    dispatch(fetchReceiptsList({ ...receiptListFilters, ...filters, offset }));
  };
  //  ================================================
  const handleSearchChange = store_name => {
    dispatch(setReceiptListStore(store_name));
    const qs = makeReceiptListQueryString(
      {
        ...receiptListFilters,
        store_name
      },
      filters
    );
    push(`/receipts?${qs}`);
    dispatch(
      fetchReceiptsList({ ...receiptListFilters, ...filters, store_name })
    );
  };
  //  ================================================
  useEffect(() => {
    if (!search) {
      const qs = makeReceiptListQueryString(receiptListFilters, filters);
      push(`/receipts?${qs}`);
    } else {
      const parsedQs = parseReceiptListQueryString(search);
      const { limit, offset, store_name, sort, fromDate, toDate } = parsedQs;

      dispatch(setReceiptListFilters({ limit, offset, store_name, sort }));
      dispatch(setFilters({ ...filters, toDate, fromDate }));
      dispatch(fetchReceiptsList(parsedQs));
      dispatch(fetchAllCategories());
    }
  }, [search]);
  //  ================================================
  return (
    <div className="content-scroll">
      <div className="content-width">
        <div className="top-heah">
          <div className="head-left">
            <h1>Receipts</h1>
          </div>

          <div className="head-right">
            <TopRowFilters
              filters={{...receiptListFilters, ...filters}}
              handleDateListClick={handleDateListClick}
              handleChangeStartDate={handleChangeStartDate}
              type="receipts-list"
              handleChangeEndDate={handleChangeEndDate}
            />
          </div>
        </div>
        <div className="section">
          <SearchBar
            handleSearchChange={handleSearchChange}
            store_name={receiptListFilters.store_name}
          />

          <ReceiptListTable
            receipts={receiptsList}
            onSelectReceipt={onSelectReceipt}
            handleSortBy={handleSortBy}
            sort_by={sort_by}
            sort_order={sort_order}
            source="receipts"
          />

          {isDataAvailable && (
            <Pagination
              handleLimitChange={handleLimitChange}
              limit={receiptListFilters.limit}
              offset={receiptListFilters.offset}
              handleChangeOffset={handleChangeOffset}
              pagination_count={
                isDataAvailable && data.length && data[0].pagination_count
              }
              total_pages={
                isDataAvailable &&
                data.length &&
                Math.ceil(
                  data[0].pagination_count / receiptListFilters.limit.value
                )
              }
              pageNeighbours={3}
            />
          )}
        </div>
      </div>
    </div>
  );
};
//  ================================================
const mapStateToProps = state => {
  const {
    filters,
    receipts: { receiptsList, receiptDetails, receiptListFilters, categories }
  } = state;
  return { receiptsList, receiptDetails, receiptListFilters, categories, filters };
};
//  ================================================
export default memo(connect(mapStateToProps)(ReceiptList));
