import isEmpty from "lodash/isEmpty";
import * as ActionTypes from "../../actions/merchant-details/salesFigures";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: {}
};

export const salesFigures = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_SALES_FIGURES:
      return { ...state, isLoading: true };

    case ActionTypes.FETCH_SALES_FIGURES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        isDataAvailable: !isEmpty(data.current) || !isEmpty(data.previous),
        data
      };
    }

    case ActionTypes.FETCH_SALES_FIGURES_FAILURE:
      return { ...initialState };

    default:
      return state;
  }
};
