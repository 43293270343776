const TOTAL_ITEMS = "TOTAL_ITEMS";
const AVERAGE_BASKET = "AVERAGE_BASKET";
const TOTAL_SPENT = "TOTAL_SPENT";
const AVERAGE_SPENT = "AVERAGE_SPENT";
const TOTAL_RECEIPTS = "TOTAL_RECEIPTS";

export {
  TOTAL_ITEMS,
  AVERAGE_BASKET,
  TOTAL_SPENT,
  AVERAGE_SPENT,
  TOTAL_RECEIPTS
};
export const propertyMap = {
  [TOTAL_ITEMS]: "totalItems",
  [TOTAL_RECEIPTS]: "totalReceipts",
  [TOTAL_SPENT]: "totalSpent",
  [AVERAGE_BASKET]: "averageBasket",
  [AVERAGE_SPENT]: "averageSpent"
};
export const tiles = [
  {
    id: 1,
    tileType: TOTAL_RECEIPTS,
    title: "Total Receipts",
    type: "number"
  },
  {
    id: 2,
    tileType: TOTAL_ITEMS,
    title: "Total Items",
    type: "number"
  },
  {
    id: 3,
    tileType: AVERAGE_BASKET,
    title: "Average Basket",
    type: "number"
  },
  {
    id: 4,
    tileType: TOTAL_SPENT,
    title: "Total Spent",
    type: "currency"
  },
  {
    id: 5,
    tileType: AVERAGE_SPENT,
    title: "Average Spent",
    type: "currency"
  }
];

export const modalConfig = {
  [TOTAL_ITEMS]: {
    title: "Total Items",
    subtitle: "View how many items were scanned over a period of time",
    graphType: "BAR",
    dataKey: "total_items",
    xAxisDataKey: "date",
    contentType: "number"
  },
  [TOTAL_RECEIPTS]: {
    title: "Total Receipts",
    subtitle: "View the volume of receipts that was scanned in by category.",
    graphType: "PIE",
    dataKey: "total_receipts",
    xAxisDataKey: "date",
    contentType: "number"
  },
  [TOTAL_SPENT]: {
    title: "Total Spent",
    subtitle: "View the total amount spent by category over time",
    graphType: "PIE",
    dataKey: "total_spent",
    xAxisDataKey: "date",
    contentType: "currency"
  },
  [AVERAGE_BASKET]: {
    title: "Average Basket Size",
    subtitle: "See the distribution curve for the average basket size",
    graphType: "BAR",
    dataKey: "frequency",
    xAxisDataKey: "bucket_size",
    contentType: "number"
  },
  [AVERAGE_SPENT]: {
    title: "Average Spent",
    subtitle:
      "See the distribution curve for the average amount spent per receipt",
    graphType: "BAR",
    dataKey: "average_spent",
    xAxisDataKey: "date",
    contentType: "currency"
  }
};
