import React, { useState, useEffect, memo } from "react";
import Slider from "react-slick";
import Loader from "../../components/SpiralLoader";
import { connect } from "react-redux";
// ================================================
import "./Login.scss";
import img from "../../images/dubai.jpg";
import img4 from "../../images/the-grand2.jpg";
import img3 from "../../images/grand-hyatt-dubai.jpg";
import img2 from "../../images/creek-edge2.jpg";
import logo from "../../images/logo.png";
import createrlogo from "../../images/creater-logo.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// ================================================
import { requestUserLogin, resetErrorState } from "../../actions/user";
// ===============================================================
const Login = props => {
  const { user, history, isLoginIncorrect, dispatch, isLoggingIn } = props;
  const signInButton = "Sign In";
  const emailLabel = "Email";
  const passwordLabel = "Password";
  // ===============================================
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  // ===============================================
  const handleEmailChange = e => {
    if (isLoginIncorrect) {
      dispatch(resetErrorState());
    }
    setEmail(e.target.value);
    setIsEmpty(false);
  };
  // ===============================================
  const handlePasswordChange = e => {
    if (isLoginIncorrect) {
      dispatch(resetErrorState());
    }
    setPassword(e.target.value);
    setIsEmpty(false);
  };
  // ===============================================
  const ValidateEmail = mail =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    );
  // ===============================================
  const handleSignIn = e => {
    e.preventDefault();
    if (!email || !password || !ValidateEmail(email)) {
      setIsEmpty(true);
    } else {
      dispatch(requestUserLogin({ email, password }));
    }
  };
  // ===============================================
  useEffect(() => {
    if (user !== null) {
      localStorage.setItem("user", JSON.stringify(user));
      history.push("/");
    }
  }, [user]);
  // ===============================================
  const errorSpan = () => {
    if (isLoginIncorrect) {
      return (
        <span className="error-message">
          Incorrect user name and / or password
        </span>
      );
    } else if (isEmpty) {
      return (
        <span className="error-message">
          Incorrect user name and / or password
        </span>
      );
    } else {
      return null;
    }
  };
  // ===============================================
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    arrows: false,
    dots: true
  };

  // ===============================================
  return (
    <div className="login-wrapper">
      <div className="login-content">
        <div className="login-header">
          <div className="login-logo">
            <a href="#/">
              <img src={logo} alt="des" />
            </a>
          </div>
        </div>
        <div className="footer-mid-content">
          <form action="#" className="form">
            {errorSpan()}
            <span className="title">EMAAR DASHBOARD</span>
            <h2>Login</h2>
            <label>{emailLabel}</label>
            <div className="input-holder mail">
              <input
                type="email"
                onChange={handleEmailChange}
                placeholder="Your email address"
                value={email}
              ></input>
            </div>
            <label>{passwordLabel}</label>
            <div className="input-holder password">
              <input
                type="password"
                onChange={handlePasswordChange}
                placeholder="Your password"
                value={password}
              ></input>
            </div>
            <div className="form-row">
              <button className="btn" onClick={handleSignIn}>
                {!isLoggingIn ? (
                  signInButton
                ) : (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "5px"
                    }}
                  >
                    <span>
                      <Loader height={20} width={100} />
                    </span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="login-footer">
          <div className="creater-logo">
            <img src={createrlogo} className="customer-img" alt="des" />
          </div>
          <span className="copyright">
            © 2019 VisionX Technologies, Inc. All rights reserved.
          </span>
        </div>
      </div>
      <div className="login-slider">
        <Slider {...settings}>
          <div className="slide">
            <img src={img} alt="des" />
          </div>
          <div className="slide">
            <img src={img2} alt="des" />
          </div>
          <div className="slide">
            <img src={img3} alt="des" />
          </div>
          <div className="slide">
            <img src={img4} alt="des" />
          </div>
        </Slider>
      </div>
    </div>
  );
};
// ===============================================================
const mapStateToProps = state => {
  return {
    user: state.user.user,
    isLoggingIn: state.user.isLoggingIn,
    isLoginIncorrect: state.user.isLoginIncorrect
  };
};

export default memo(connect(mapStateToProps)(Login));
