import moment from "moment";
import { humanizeCurrency } from "../../utils";
export const formatlabel = (value, name, props) => {
  const {
    payload: { currentDate, previousDate }
  } = props;

  const formattedName = name === "Current Period" ? currentDate : previousDate;

  return [
    humanizeCurrency({ amount: value, type: "currency" }),
    moment(formattedName, "YYYY-MM-DD").format("DD-MMM-YY"),
    props
  ];
};
