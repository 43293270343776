import { CALL_API } from '../../apiMiddleware'

export const REQUEST_USER_LOGIN = 'REQUEST_USER_LOGIN'
export const REQUEST_USER_LOGIN_SUCCESS = 'REQUEST_USER_LOGIN_SUCCESS'
export const REQUEST_USER_LOGIN_FAILURE = 'REQUEST_USER_LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'

export const resetErrorState = () => ({ type: RESET_ERROR_STATE })

export const logout = () => ({
  type: LOGOUT
})

export const requestUserLogin = ({ email, password }) => ({
  [CALL_API]: {
    types: [REQUEST_USER_LOGIN, REQUEST_USER_LOGIN_SUCCESS, REQUEST_USER_LOGIN_FAILURE],
    endpoint: `/auth/login`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    }
  }
})