export const groupCategories = data => {
  if (Object.keys(data).length) {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.total_receipts < b.total_receipts) {
        return 1;
      } else {
        return -1;
      }
    });
    return sortedData;
  }
  return false;
};
