import * as ActionTypes from '../../actions/user'

const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
  isLoggingIn: false,
  isLoginIncorrect: false
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_USER_LOGIN:
      return { ...state, isLoggingIn: true }

    case ActionTypes.REQUEST_USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoginIncorrect: false,
        user: action.payload.user
      }

      case ActionTypes.RESET_ERROR_STATE:
        return {
          ...state, 
          isLoginIncorrect: false
        }

      case ActionTypes.LOGOUT: {
        localStorage.removeItem('user')
        return {
          ...state,
          user: null
        }
      }

      case ActionTypes.REQUEST_USER_LOGIN_FAILURE:
        return {
          ...state,
          isLoggingIn: false,
          isLoginIncorrect: true
        }
    default:
      return state
  }
}