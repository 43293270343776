import moment from "moment";
export * from "./queries/receipt";
export * from "./queries/merchant";
export * from "./queries/dashboard";
export * from "./reduxUtils";
// =======================================================================
export const COLORS_VAL = [
  "#D72300",
  "#384EFE",
  "#45C1A6",
  "#F8D704",
  "#C71961",
  "#42CA51",
  "#AB00FF",
  "#81D5FB",
  "#64DE10",
  "#6202EB",
  "#48B9D5",
  "#F36C08",
  "#222F35",
  "#3B231E",
  "#63FEDC",
  "#F9CDBE",
  "#C6CCE9",
  "#F8BDD2",
  "#1C1C1C",
  "#FF2900"
];
// =======================================================================
export const mapSort = {};
// =======================================================================

export const formatDate = e => {
  const date = moment(e, "YYYY-MM-DD", true);
  return date.isValid() ? date.format("DD-MMM-YY") : e;
};
// =======================================================================

export const tooltipFormatter = val => {
  const date = moment(val, "YYYY-MM-DD", true);
  return date.isValid() ? date.format("DD-MMM-YY") : `Bucket Size: ${val}`;
};
// =======================================================================

export const BarChartLabelFormatter = (value, name, props) => [
  value,
  name.replace(/_/g, " "),
  props
];
// =======================================================================

export const humanizeCurrency = ({ amount, type, chart }) => {
  // type = currency || number

  let currency = "";
  let roundOffDigits = 2;
  try {
    let numberValue = Number(amount);
    let postFix = ["K", "M", "B"];
    if (type === "currency") {
      currency = "AED";
    }
    if ((chart && chart === "sales_figures") || chart === "sales_report") {
      roundOffDigits = 0;
    }
    if (numberValue > 1000000000) {
      return `${currency} ${(numberValue / 1000000000).toFixed(
        roundOffDigits
      )} ${postFix[2]}`;
    } else if (numberValue < 1000000000 && numberValue > 1000000) {
      return `${currency} ${(numberValue / 1000000).toFixed(roundOffDigits)} ${
        postFix[1]
      }`;
    } else if (numberValue < 1000000 && numberValue > 1000) {
      return `${currency} ${(numberValue / 1000).toFixed(roundOffDigits)} ${
        postFix[0]
      }`;
    } else {
      return `${currency} ${numberValue.toFixed(roundOffDigits)}`;
    }
  } catch (e) {
    return `${currency} 0`;
  }
};
// =======================================================================

export const mapCategoriesToColors = receiptsBreakdown => {
  const sortedData = [...receiptsBreakdown];
  sortedData.sort((a, b) => {
    if (a.total_receipts < b.total_receipts) {
      return 1;
    } else {
      return -1;
    }
  });
  let colors = {};
  sortedData.forEach((entry, i) => {
    colors[entry.brand_category] = COLORS_VAL[i % COLORS_VAL.length];
  });
  return colors;
};
// =======================================================================

export const transformBasicStatsChartData = chart => {
  if (chart && chart.length > 0) {
    const fixedCategories = 19;
    const sortedData = [...chart];
    sortedData.sort((a, b) => {
      if (a.total_receipts) {
        if (a.total_receipts < b.total_receipts) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (a.total_spent < b.total_spent) {
          return 1;
        } else {
          return -1;
        }
      }
    });
    let others = 0;
    const newChart = sortedData.map((c, i) => {
      if (i <= fixedCategories) {
        return c;
      }
      if (c.total_receipts) {
        others += Number(c.total_receipts);
      } else {
        others += Number(c.total_spent);
      }

      return c;
    });
    newChart.splice(fixedCategories);
    if (newChart[0].total_receipts) {
      if (others !== 0) {
        newChart.push({
          total_receipts: others,
          category: "Other"
        });
      }
    } else {
      if (others !== 0) {
        newChart.push({
          total_spent: others,
          category: "Other"
        });
      }
    }
    return newChart;
  }
  return [];
};
// =======================================================================
