import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';

import { apiMiddleware } from './apiMiddleware'
import rootReducer from './reducers/index'

const logger = createLogger({ 
  collapsed: true,
  predicate: () => process.env.NODE_ENV !== 'production' 
})

const store = createStore(rootReducer, applyMiddleware(thunk, apiMiddleware, logger))

export default store;
