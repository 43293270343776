import React, { memo } from "react";
import { omit, uniq } from "lodash";

import EmaarLoader from "../EmaarLoader";
import StackedBarChart from "../StackedBarChart";
import { groupCategories } from "./utils";

const SalesReportSection = memo(
  ({ receiptsBreakdown, isLoading, isDataAvailable, data, colors }) => {
    const newCategories = groupCategories(receiptsBreakdown.data);
    const availableCategories =
      !isLoading &&
      isDataAvailable &&
      uniq(
        data.reduce((arr, item) => {
          return [...arr, ...Object.keys(omit(item, ["month"]))];
        }, [])
      );
    return (
      <div className="section">
        <h2>Sales Report</h2>
        <span className="text">Last 6 months</span>
        {isLoading && <EmaarLoader />}
        {!isLoading && !isDataAvailable && <h1>No Data</h1>}
        {!isLoading && isDataAvailable && (
          <StackedBarChart
            salesReport={data}
            categories={availableCategories}
            colors={colors}
            receiptsCategories={newCategories}
          />
        )}
      </div>
    );
  }
);

export default SalesReportSection;
