import React from "react";
import { connect } from "react-redux";
import ReactExport from "react-export-excel";
import { isEmpty } from "lodash";
// =========================================================
import {
  getSalesFiguresData,
  getSalesReportData,
  getFiltersDataSet,
  getTotalReceiptsShorter,
  getTotalItemsDataSet,
  getAverageBasketDataSet,
  getTotalSpentDataSet,
  getAverageSpentDataSet,
  getTotalReceiptsLonger,
  getTopMerchantsDataset
} from "./ExcelReportUtils";
// =========================================================
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// =========================================================
const Download = props => {
  // -----------------------------------------------------------
  const {
    dashboard: {
      salesReport,
      receiptsBreakdown,
      topMerchants,
      salesFigures,
      basicStats: {
        averageBasket,
        averageSpent,
        totalSpent,
        totalItems,
        totalReceipts
      }
    },
    filters: { selectedCategories, fromDate, toDate }
  } = props;
  // -----------------------------------------------------------
  const isExcelReady =
    selectedCategories.length > 1 &&
    !isEmpty(totalReceipts.data) &&
    !isEmpty(totalItems.data) &&
    !isEmpty(averageBasket.data) &&
    !isEmpty(totalSpent.data) &&
    !isEmpty(averageSpent.data) &&
    !isEmpty(salesFigures.data) &&
    !isEmpty(receiptsBreakdown.data) &&
    !isEmpty(salesReport.data) &&
    !isEmpty(topMerchants.data);
  // -----------------------------------------------------------
  const loader = isExcelReady ? (
    <i className="icon-file"></i>
  ) : (
    <i className="fas fa-spinner fa-spin" />
  );
  const downloadElement = (
    <a href="#/" className="generate-link">
      {loader}
    </a>
  );
  return (
    <ExcelFile element={downloadElement}>
      {selectedCategories.length > 1 && (
        <ExcelSheet
          dataSet={getFiltersDataSet(selectedCategories, toDate, fromDate)}
          name="Filters"
        />
      )}
      {totalReceipts.isDataAvailable && !isEmpty(totalReceipts.data) ? (
        <ExcelSheet
          dataSet={getTotalReceiptsShorter(totalReceipts.data.chart)}
          name="Total Receipts"
        />
      ) : null}
      {totalItems.isDataAvailable && !isEmpty(totalItems.data) ? (
        <ExcelSheet
          dataSet={getTotalItemsDataSet(totalItems.data.chart)}
          name="Total Items"
        />
      ) : null}
      {averageBasket.isDataAvailable && !isEmpty(averageBasket.data) && (
        <ExcelSheet
          dataSet={getAverageBasketDataSet(averageBasket.data.chart)}
          name="Average Basket Size"
        />
      )}

      {totalSpent.isDataAvailable && !isEmpty(totalSpent.data) && (
        <ExcelSheet
          dataSet={getTotalSpentDataSet(totalSpent.data.chart)}
          name="Total Spent"
        />
      )}
      {averageSpent.isDataAvailable && !isEmpty(averageSpent.data) && (
        <ExcelSheet
          dataSet={getAverageSpentDataSet(averageSpent.data.chart)}
          name="Average Spent"
        />
      )}

      {salesFigures.isDataAvailable && !isEmpty(salesFigures.data) && (
        <ExcelSheet
          dataSet={getSalesFiguresData({ ...props })}
          name="Sales Figures"
        ></ExcelSheet>
      )}
      {receiptsBreakdown.isDataAvailable &&
        !isEmpty(receiptsBreakdown.data) && (
          <ExcelSheet
            dataSet={getTotalReceiptsLonger(receiptsBreakdown.data)}
            name="Total Receipts Last Six Months"
          />
        )}
      {salesReport.isDataAvailable && !isEmpty(salesReport.data) && (
        <ExcelSheet
          dataSet={getSalesReportData({ ...salesReport })}
          name="Sales Report Last Six Months"
        />
      )}

      {topMerchants.isDataAvailable && !isEmpty(topMerchants.data) && (
        <ExcelSheet
          dataSet={getTopMerchantsDataset(topMerchants.data)}
          name="Top Merchants"
        ></ExcelSheet>
      )}
    </ExcelFile>
  );
};

export default connect(state => state)(Download);
