import moment from "moment";
import * as ActionTypes from "../../actions/receipts";

const initialState = {
  limit: {
    value: 10,
    label: "10"
  },
  offset: 0,
  store_name: "",
  sort: {
    value: "DESC",
    label: "default"
  },
  visible: false
};

const {
  SET_RECEIPT_LIST_LIMIT,
  SET_RECEIPT_LIST_FILTERS,
  SET_RECEIPT_LIST_OFFSET,
  SET_RECEIPT_LIST_STORE,
  RESET_RECEIPT_LIST_FILTERS,
  TOGGLE_RECEIPT_LIST_FILTER_VISIBILITY,
  TOGGLE_RECEIPT_LIST_CATEGORY,
  TOGGLE_RECEIPT_LIST_CATEGORIES_ALL,
  SORT_RECEIPT_LIST_ORDER,
  SORT_RECEIPT_LIST_BY
} = ActionTypes;
const receiptListFilters = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECEIPT_LIST_FILTERS:
      return { ...state, ...action.payload };

    case SET_RECEIPT_LIST_LIMIT:
      return { ...state, limit: { ...action.payload } };

    case SET_RECEIPT_LIST_OFFSET:
      return { ...state, offset: action.payload };

    case SET_RECEIPT_LIST_STORE:
      return {
        ...state,
        store_name: action.payload
      };

    // case SET_RECEIPT_LIST_SORT:
    //   return {
    //     ...state,
    //     sort: { ...action.payload }
    //   };
    case RESET_RECEIPT_LIST_FILTERS:
      return { ...initialState, ...action.payload };

    case TOGGLE_RECEIPT_LIST_FILTER_VISIBILITY:
      return { ...state, visible: !state.visible };

    case TOGGLE_RECEIPT_LIST_CATEGORY: {
      // ====================================================
      //  FOR SELECTION OF ONLY SINGLE CATEGORY AT A TIME
      // ====================================================
      const { selectedCategories } = state;
      const index = selectedCategories.indexOf(action.payload);
      if (index === -1) {
        return {
          ...state,
          selectedCategories: [action.payload]
        };
      } else {
        const newSelectedCategories = [...state.selectedCategories];
        newSelectedCategories.splice(index, 1);
        return {
          ...state,
          selectedCategories: newSelectedCategories
        };
      }
      // ====================================================
      //  FOR SELECTION OF MULITPLE CATEGORIES AT A TIME
      // ====================================================
      // const { selectedCategories } = state;
      // if (selectedCategories.indexOf(action.payload) === -1) {
      //   return {
      //     ...state,
      //     selectedCategories: [...state.selectedCategories, action.payload]
      //   };
      // } else {
      //   return {
      //     ...state,
      //     selectedCategories: [...state.selectedCategories].filter(
      //       item => item !== action.payload
      //     )
      //   };
      // }
    }
    case TOGGLE_RECEIPT_LIST_CATEGORIES_ALL:
      return { ...state, selectedCategories: action.payload };

    case SORT_RECEIPT_LIST_BY:
      return {
        ...state,
        sort_by: action.payload
      };
    case SORT_RECEIPT_LIST_ORDER:
      return {
        ...state,
        sort_order: action.payload
      };
    default:
      return state;
  }
};

export { receiptListFilters };
