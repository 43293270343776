import { CALL_API } from "../../apiMiddleware";
export * from "./filters";
export * from "./categories";
export const FETCH_MERCHANTS_LIST = "FETCH_MERCHANTS_LIST";
export const FETCH_MERCHANTS_LIST_SUCCESS = "FETCH_MERCHANTS_LIST_SUCCESS";
export const FETCH_MERCHANTS_LIST_FAILURE = "FETCH_MERCHANTS_LIST_FAILURE";

export const fetchMerchantsList = ({
  fromDate,
  toDate,
  limit,
  offset,
  store_name = "",
  merchantCategories = [],
  sort_order = "DESC",
  sort_by = "revenue"
}) => {
  let category = merchantCategories[0] || "";
  if (merchantCategories.length > 1) {
    category = "";
  }
  return {
    [CALL_API]: {
      types: [
        FETCH_MERCHANTS_LIST,
        FETCH_MERCHANTS_LIST_SUCCESS,
        FETCH_MERCHANTS_LIST_FAILURE
      ],
      endpoint: `/merchants?limit=${limit.value}&offset=${offset}&from_date=${fromDate}&to_date=${toDate}&sort_by=${sort_by}&sort_order=${sort_order}&store_name=${store_name}&category=${category}`,
      options: {
        method: "GET"
      }
    }
  };
};
