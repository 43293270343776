import React, { memo } from "react";
import EmaarLoader from "../../../components/EmaarLoader";
import { humanizeCurrency } from "../../../utils";
import { headers } from "./utils";
// ==============================================================
const ReceiptListTable = ({
  receipts: { isLoading, isDataAvailable, data },
  openModal,
  sort_order,
  sort_by,
  onSelectReceipt,
  handleSortBy,
  source
}) => {
  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th
              key={i}
              onClick={
                header.value !== "request_id" && source !== "merchants"
                  ? () => handleSortBy(header.value)
                  : () => {}
              }
            >
              <span>{header.label.toUpperCase()}</span>
              <span
                style={{
                  fontSize: "15px",
                  margin: "3px",
                  paddingTop: "3px",
                  alignItems: "center"
                }}
              >
                {header.value !== "request_id" &&
                header.value &&
                source !== "merchants" ? (
                  <i
                    className={`fas fa-caret-${
                      sort_order === "DESC" && sort_by === header.value
                        ? "down"
                        : "up"
                    }`}
                  ></i>
                ) : null}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <EmaarLoader />
        ) : (
          isDataAvailable &&
          data.map(r => (
            <tr key={r.request_id} onClick={() => onSelectReceipt(r)}>
              <td>
                <span className="file-link">
                  <a href="#/" onClick={openModal}>
                    {r.request_id}
                  </a>
                </span>
              </td>
              <td>{r.store_name}</td>
              <td>{r.date.value}</td>
              <td>{r.basket_size === null ? "N/A" : r.basket_size}</td>
              <td>
                {r.total_amount === null
                  ? "N/A"
                  : humanizeCurrency({
                      amount: r.total_amount,
                      type: "currency"
                    })}
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};
// ==============================================================
export default memo(ReceiptListTable);
