import * as ActionTypes from "../../actions/merchant-details/recent-receipts";
import { createReducer } from "../../utils";

const recentReceipts = createReducer([
  ActionTypes.FETCH_RECENT_RECEIPTS_MERCHANTS,
  ActionTypes.FETCH_RECENT_RECEIPTS_MERCHANTS_SUCCESS,
  ActionTypes.FETCH_RECENT_RECEIPTS_MERCHANTS_FAILURE
]);

export { recentReceipts };
