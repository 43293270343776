import { humanizeCurrency } from "../../../utils";
import { chunk } from "lodash";

export const getTotalReceiptsShorter = chart => {
  const categories = chart && chart.length ? chart.map(c => c.category) : [];
  const receipts =
    chart && chart.length
      ? chart.map(c =>
          humanizeCurrency({ amount: c.total_receipts, type: "number" })
        )
      : [];
  return [
    {
      columns: ["Categories"],
      data: categories.length ? [[""], ...chunk(categories, 1)] : []
    },
    {
      xSteps: 3,
      ySteps: -(categories.length + 2),
      columns: ["Receipts"],
      data: receipts.length ? [[""], ...chunk(receipts, 1)] : []
    }
  ];
};
