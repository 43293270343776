import React, { memo } from "react";
import EmaarLoader from "../EmaarLoader";
import SalesFigureChart from "../SalesFigureChart";
import { getTransformedSalesFiguresData } from "./utils";

const SalesFiguresSection = memo(
  ({ isLoading, isDataAvailable, data, fromDate, toDate }) => {
    const transformedData =
      !isLoading && isDataAvailable
        ? getTransformedSalesFiguresData(fromDate, toDate, data)
        : data;

    return (
      <div className="section">
        <h2>Sales Figures</h2>
        <div className="graph-area">
          {isLoading && (
            <div>
              <EmaarLoader />
            </div>
          )}
          {!isLoading && !isDataAvailable && (
            <div
              style={{
                width: "100%",
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <h1>No Data</h1>
            </div>
          )}
          {!isLoading && isDataAvailable && (
            <SalesFigureChart salesFigures={transformedData} />
          )}
        </div>
      </div>
    );
  }
);

export default SalesFiguresSection;
