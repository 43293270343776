export const createReducer = (actionTypes) => {
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: {}
  }

  const [FETCH, SUCCESS, FAILURE] = actionTypes

  return (state = initialState, action) => {
    switch (action.type) {
      case FETCH:
        return { ...state, isLoading: true }

      case SUCCESS:
        return { ...state, isLoading: false, isDataAvailable: true, data: action.payload.data }

      case FAILURE:
        return initialState

      default:
        return state
    }
  }
}