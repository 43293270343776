import React, { memo } from "react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer
} from "recharts";
import { formatDate, humanizeCurrency } from "../../utils";
import { formatlabel } from "./utils";
// ==========================================================
export default memo(({ salesFigures }) => (
  <ResponsiveContainer width={"100%"} height={350}>
    <LineChart
      data={salesFigures}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid horizontal={false} />
      <XAxis dataKey="date" tickFormatter={formatDate} />
      <YAxis
        width={90}
        tickFormatter={value =>
          humanizeCurrency({
            amount: value,
            type: "currency",
            chart: "sales_figures"
          })
        }
      />
      <Tooltip labelFormatter={() => null} formatter={formatlabel} />
      <Legend verticalAlign="top" align="right" height={36} />
      <Line
        type="monotone"
        legendType="circle"
        dataKey="Previous Period"
        stroke="#49d59a"
        strokeWidth={2}
        dot={false}
        activeDot={{ r: 5 }}
      />
      <Line
        type="monotone"
        legendType="circle"
        dataKey="Current Period"
        stroke="#1167f8"
        strokeWidth={2}
        dot={false}
        activeDot={{ r: 5 }}
      />
    </LineChart>
  </ResponsiveContainer>
));
