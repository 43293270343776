import { CALL_API } from "../../apiMiddleware";
export const FETCH_SALES_FIGURES = "FETCH_SALES_FIGURES";
export const FETCH_SALES_FIGURES_SUCCESS = "FETCH_SALES_FIGURES_SUCCESS";
export const FETCH_SALES_FIGURES_FAILURE = "FETCH_SALES_FIGURES_FAILURE";
export const fetchSalesFigures = ({ fromDate, toDate, store_name }) => ({
  [CALL_API]: {
    types: [
      FETCH_SALES_FIGURES,
      FETCH_SALES_FIGURES_SUCCESS,
      FETCH_SALES_FIGURES_FAILURE
    ],
    endpoint: `/merchants/sales-figures/${store_name}?from_date=${fromDate}&to_date=${toDate}`,
    options: {
      method: "GET"
    }
  }
});
