import React, { memo } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import ExcelReport from "../ExcelReport";
import CustomInput from "../CustomInput";
import FilterCategories from "../FilterCategories";
// ==============================================================
const TopRowFilters = ({
  type,
  filters: {
    fromDate,
    toDate,
    selectedCategories,
    merchantCategories,visible
  },
  merchantListFilters,
  handleDateListClick,
  handleChangeStartDate,
  handleChangeEndDate,
  toggleFilter,
  categories,
  selectCategories,
  toggleAllCategories,
  handleFilterButton
}) => {
  let filterSelectedCategories = selectedCategories ;
  if(type === 'merchant-list'){
    filterSelectedCategories = merchantCategories
    visible = merchantListFilters.visible
  }
  const dateDiff = moment(toDate).diff(moment(fromDate), "days");
  return (
    <>
      <ul className="time-filter">
        <li className={dateDiff === 1 ? "active" : ""}>
          <span onClick={() => handleDateListClick(1)}>1 Day</span>
        </li>
        <li className={dateDiff === 7 ? "active" : ""}>
          <span onClick={() => handleDateListClick(7)}>7 Days</span>
        </li>
        <li className={dateDiff === 30 ? "active" : ""}>
          <span onClick={() => handleDateListClick(30)}>1 Month</span>
        </li>
        <li className={dateDiff === 90 ? "active" : ""}>
          <span onClick={() => handleDateListClick(90)}>3 Months</span>
        </li>
      </ul>
      <div className="datepicker-holder">
        <div className="date-from">
          <DatePicker
            selected={moment(fromDate).toDate()}
            onSelect={handleChangeStartDate}
            disabledKeyboardNavigation={true}
            // maxDate={moment().toDate()}
            showYearDropdown={true}
            showMonthDropdown={true}
            customInput={
              <CustomInput
                active="custom-input-dp-active"
                dateDiff={dateDiff}
              />
            }
          />
        </div>
        <span className="dash">-</span>
        <div className="date-to">
          <DatePicker
            selected={moment(toDate).toDate()}
            onSelect={handleChangeEndDate}
            disabledKeyboardNavigation={true}
            showYearDropdown={true}
            showMonthDropdown={true}
            customInput={
              <CustomInput
                active="custom-input-dp-active"
                dateDiff={dateDiff}
              />
            }
          />
        </div>
      </div>
      {type !== "merchant-details" && type !== "receipts-list" && (
        <FilterCategories
          toggleFilter={toggleFilter}
          showFilter={visible}
          selectCategories={selectCategories}
          allCategories={categories.data}
          toggleAllCategories={toggleAllCategories}
          filterSelectedCategories={filterSelectedCategories}
          handleFilterButton={handleFilterButton}
          type={type}
        />
      )}
      {type === "dashboard" && <ExcelReport />}
    </>
  );
};

export default memo(TopRowFilters);
