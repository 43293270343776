import { CALL_API } from "../../apiMiddleware";

export const FETCH_RECEIPT_LIST_ALL_CATEGORIES =
  "FETCH_RECEIPT_LIST_ALL_CATEGORIES";
export const FETCH_RECEIPT_LIST_ALL_CATEGORIES_SUCCESS =
  "FETCH_RECEIPT_LIST_ALL_CATEGORIES_SUCCESS";
export const FETCH_RECEIPT_LIST_ALL_CATEGORIES_FAILURE =
  "FETCH_RECEIPT_LIST_ALL_CATEGORIES_FAILURE";

export const fetchAllCategories = () => ({
  [CALL_API]: {
    types: [
      FETCH_RECEIPT_LIST_ALL_CATEGORIES,
      FETCH_RECEIPT_LIST_ALL_CATEGORIES_SUCCESS,
      FETCH_RECEIPT_LIST_ALL_CATEGORIES_FAILURE
    ],
    endpoint: `/categories`,
    options: {
      method: "GET"
    }
  }
});
