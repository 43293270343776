import { CALL_API } from "../../apiMiddleware";

export * from "./basicStats";
export * from "./filters";

export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES";
export const FETCH_ALL_CATEGORIES_SUCCESS = "FETCH_ALL_CATEGORIES_SUCCESS";
export const FETCH_ALL_CATEGORIES_FAILURE = "FETCH_ALL_CATEGORIES_FAILURE";

export const FETCH_SALES_FIGURES = "FETCH_SALES_FIGURES";
export const FETCH_SALES_FIGURES_SUCCESS = "FETCH_SALES_FIGURES_SUCCESS";
export const FETCH_SALES_FIGURES_FAILURE = "FETCH_SALES_FIGURES_FAILURE";

export const FETCH_RECEIPTS_BREAKDOWN = "FETCH_RECEIPTS_BREAKDOWN";
export const FETCH_RECEIPTS_BREAKDOWN_SUCCESS =
  "FETCH_RECEIPTS_BREAKDOWN_SUCCESS";
export const FETCH_RECEIPTS_BREAKDOWN_FAILURE =
  "FETCH_RECEIPTS_BREAKDOWN_FAILURE";

export const FETCH_SALES_REPORT = "FETCH_SALES_REPORT";
export const FETCH_SALES_REPORT_SUCCESS = "FETCH_SALES_REPORT_SUCCESS";
export const FETCH_SALES_REPORT_FAILURE = "FETCH_SALES_REPORT_FAILURE";

export const FETCH_TOP_MERCHANTS = "FETCH_TOP_MERCHANTS";
export const FETCH_TOP_MERCHANTS_SUCCESS = "FETCH_TOP_MERCHANTS_SUCCESS";
export const FETCH_TOP_MERCHANTS_FAILURE = "FETCH_TOP_MERCHANTS_FAILURE";

export const fetchAllCategories = () => ({
  [CALL_API]: {
    types: [
      FETCH_ALL_CATEGORIES,
      FETCH_ALL_CATEGORIES_SUCCESS,
      FETCH_ALL_CATEGORIES_FAILURE
    ],
    endpoint: `/categories`,
    options: {
      method: "GET"
    }
  }
});

export const fetchSalesFigures = ({
  fromDate,
  toDate,
  selectedCategories
}) => ({
  [CALL_API]: {
    types: [
      FETCH_SALES_FIGURES,
      FETCH_SALES_FIGURES_SUCCESS,
      FETCH_SALES_FIGURES_FAILURE
    ],
    endpoint: `/dashboard/salesFigures?from_date=${fromDate}&to_date=${toDate}&categories=${selectedCategories.join(
      ","
    )}`,
    options: {
      method: "GET"
    }
  }
});

export const fetchReceiptsBreakdown = () => ({
  [CALL_API]: {
    types: [
      FETCH_RECEIPTS_BREAKDOWN,
      FETCH_RECEIPTS_BREAKDOWN_SUCCESS,
      FETCH_RECEIPTS_BREAKDOWN_FAILURE
    ],
    endpoint: `/dashboard/receiptsBreakdown`,
    options: {
      method: "GET"
    }
  }
});

export const fetchSalesReport = () => ({
  [CALL_API]: {
    types: [
      FETCH_SALES_REPORT,
      FETCH_SALES_REPORT_SUCCESS,
      FETCH_SALES_REPORT_FAILURE
    ],
    endpoint: `/dashboard/salesReport`,
    options: {
      method: "GET"
    }
  }
});

export const fetchTopMerchants = () => ({
  [CALL_API]: {
    types: [
      FETCH_TOP_MERCHANTS,
      FETCH_TOP_MERCHANTS_SUCCESS,
      FETCH_TOP_MERCHANTS_FAILURE
    ],
    endpoint: `/dashboard/topMerchants`,
    options: {
      method: "GET"
    }
  }
});
