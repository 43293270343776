import React, { memo } from "react";
import { ResponsiveContainer } from "recharts";

import EmaarLoader from "../EmaarLoader";
import PieChart from "../PieChart/PieChart";

const ReceiptsBreakdownSection = memo(
  ({ isLoading, isDataAvailable, data, colors }) => {
    return (
      <div className="section">
        <h2>Total Recipts</h2>
        <span className="text">Last 6 months</span>

        {isLoading && <EmaarLoader />}
        {!isLoading && !isDataAvailable && <h1>No Data</h1>}
        {!isLoading && isDataAvailable && (
          <ResponsiveContainer width={"100%"} height={480}>
            <PieChart data={data} colors={colors} />
          </ResponsiveContainer>
        )}

        <span className="bottom-info">
          This gives you the approximate proportion regarding which types of
          stores customers visit. You can manage a full breakdown of stores and
          their categories in the settings menu.{" "}
        </span>
      </div>
    );
  }
);

export default ReceiptsBreakdownSection;
