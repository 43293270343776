export const SET_MERCHANT_DETAILS_FROM_DATE = "SET_MERCHANT_DETAILS_FROM_DATE";
export const SET_MERCHANT_DETAILS_TO_DATE = "SET_MERCHANT_DETAILS_TO_DATE";
export const SET_MULTIPLE_CATEGORIES = "SET_MULTIPLE_CATEGORIES";
export const SET_MERCHANT_DETAILS_DATERANGE = "SET_MERCHANT_DETAILS_DATERANGE";
export const SET_MERCHANT_DETAILS_FILTERS = "SET_MERCHANT_DETAILS_FILTERS";
export const SET_MERCHANT_DETAILS_LIMIT = "SET_MERCHANT_DETAILS_LIMIT";
export const SET_MERCHANT_DETAILS_OFFSET = "SET_MERCHANT_DETAILS_OFFSET";
export const SET_MERCHANT_DETAILS_SEARCH = "SET_MERCHANT_DETAILS_SEARCH";
export const RESET_MERCHANT_DETAILS_FILTERS = "RESET_MERCHANT_DETAILS_FILTERS";

export const setMerchantDetailsFromDate = fromDate => ({
  type: SET_MERCHANT_DETAILS_FROM_DATE,
  payload: fromDate
});

export const setMerchantDetailsToDate = toDate => ({
  type: SET_MERCHANT_DETAILS_TO_DATE,
  payload: toDate
});

export const setMerchantDetailsDateRange = (fromDate, toDate) => ({
  type: SET_MERCHANT_DETAILS_DATERANGE,
  payload: { fromDate, toDate }
});

export const setMerchantDetailsFilters = filters => ({
  type: SET_MERCHANT_DETAILS_FILTERS,
  payload: filters
});

export const resetMerchantDetailsFilters = filters => ({
  type: RESET_MERCHANT_DETAILS_FILTERS,
  payload: filters
});
