import React from "react";

import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
const EmaarTable = ({
  headers,
  rowData,
  onMerchantClick,
  handleSortBy,
  sort_order,
  sort_by
}) => (
  <table>
    <thead>
      <tr>
        <TableHeader
          headers={headers}
          handleSortBy={handleSortBy}
          sort_order={sort_order}
          sort_by={sort_by}
        />
      </tr>
    </thead>
    <tbody>
      <TableBody rowData={rowData} onMerchantClick={onMerchantClick} />
    </tbody>
  </table>
);
export default EmaarTable;
