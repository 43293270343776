import React, { memo } from "react";
import Modal from "react-modal";
import { isEmpty } from "lodash";
import { Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { renderCustomizedlabel } from "./renderCustomizedlabel";
import { humanizeCurrency } from "../../utils";
import { customStyles } from "./utils";
// ===============================================================
export default memo(({ showModal, closeModal, data, colors }) => (
  <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
    <div className="modal-head add" id="modal">
      <div className="action-area">
        <button onClick={closeModal} className="icon-close" />
      </div>
      <h1>Reciepts Overview</h1>
    </div>
    <div className="modal-content" style={{ height: 600, width: "100%" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "30%" }} className="chart-list">
          <ul className="gradient-list">
            {!isEmpty(data) &&
              data.map((entry, index) => (
                <li key={entry.id || index}>
                  <i
                    className="fas fa-circle"
                    style={{
                      color: colors[entry.brand_category]
                    }}
                  />
                  {`  ${entry.brand_category} : ${humanizeCurrency({
                    amount: entry.total_receipts,
                    type: "number"
                  })}`}
                </li>
              ))}
          </ul>
        </div>
        <div style={{ width: "80%", height: 550 }} id="modal">
          <ResponsiveContainer>
            {!isEmpty(data) && (
              <PieChart>
                <Pie
                  innerRadius={160}
                  outerRadius={230}
                  dataKey={"total_receipts"}
                  nameKey={"brand_category"}
                  isAnimationActive={true}
                  data={data}
                  cx={"52%"}
                  cy={"50%"}
                  fill="#8884d8"
                  label={renderCustomizedlabel}
                >
                  {!isEmpty(data) &&
                    data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[entry.brand_category]}
                      />
                    ))}
                </Pie>
                <Tooltip
                  formatter={value =>
                    humanizeCurrency({ amount: value, type: "number" })
                  }
                />
              </PieChart>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  </Modal>
));
