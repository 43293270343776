import React from "react";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import { humanizeCurrency } from "../../utils";
const Chart = ({ colors, newChart, dataKey }) => (
  <PieChart width={430} height={250}>
    <Legend
      verticalAlign="bottom"
      align="center"
      height={25}
      content={renderLegend}
      colors={colors}
    />
    <Pie
      data={newChart}
      legendType={"circle"}
      dataKey={dataKey}
      nameKey={"category"}
      cx="50%"
      cy="40%"
      outerRadius={80}
      fill="#8884d8"
    >
      {newChart.map((entry, index) => {
        return <Cell key={`cell-${index}`} fill={colors[entry.category]} />;
      })}
    </Pie>
    <Tooltip
      formatter={value =>
        humanizeCurrency({
          amount: value,
          type: "number"
        })
      }
      contentStyle={{
        fontSize: "9px",
        padding: "4px",
        borderRadius: "5px"
      }}
    />
  </PieChart>
);

const renderLegend = props => {
  const { payload, colors } = props;

  return (
    <ul className="analytics-modal-legend">
      {payload.map((entry, index) => (
        <li className="legend-item" key={`item-${index}`}>
          <span
            className="circle"
            style={{ backgroundColor: colors[entry.value] || "#000" }}
          ></span>
          <span className="legend-value">{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};
export default Chart;
