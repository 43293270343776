import { omit } from "lodash";
export const normalize_sales_report_data = data => {
  return (
    data &&
    data.map(item => {
      let sum = 0;
      const keys = Object.keys(omit(item, ["month"]));
      keys.forEach(key => {
        sum = sum + item[key];
      });
      const mapped = {};
      keys.forEach(key => {
        mapped[key] = round_to_n_decimals((item[key] / sum) * 100, 1);
      });
      return Object.assign({}, item, mapped);
    })
  );
};

const round_to_n_decimals = (number, n) => {
  if (!number) {
    return number;
  } else {
    const divFactor = Math.pow(10, n);
    return Math.round(number * divFactor) / divFactor;
  }
};
