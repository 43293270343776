import { humanizeCurrency } from "../../../utils";
import { chunk } from "lodash";
export const getTotalReceiptsLonger = data => {
  const categories = data && data.length ? data.map(c => c.brand_category) : [];
  const receipts =
    data && data.length
      ? data.map(c =>
          humanizeCurrency({ amount: c.total_receipts, type: "number" })
        )
      : [];
  return [
    {
      columns: ["Categories"],
      data: categories.length ? [[""], ...chunk(categories, 1)] : []
    },
    {
      xSteps: 3,
      ySteps: -(categories.length + 2),
      columns: ["Receipts"],
      data: receipts.length ? [[""], ...chunk(receipts, 1)] : []
    }
  ];
};
