import { CALL_API } from "../../apiMiddleware";
export const FETCH_RECEIPTS_LIST = "FETCH_RECEIPTS_LIST";
export const FETCH_RECEIPTS_LIST_SUCCESS = "FETCH_RECEIPTS_LIST_SUCCESS";
export const FETCH_RECEIPTS_LIST_FAILURE = "FETCH_RECEIPTS_LIST_FAILURE";

export const fetchReceiptsList = ({
  fromDate,
  toDate,
  limit,
  offset,
  store_name = "",
  selectedCategories = [],
  sort_order = "DESC",
  sort_by = "date"
}) => {
  let category = selectedCategories[0] || "";
  if (selectedCategories.length > 1) {
    category = "";
  }
  return {
    [CALL_API]: {
      types: [
        FETCH_RECEIPTS_LIST,
        FETCH_RECEIPTS_LIST_SUCCESS,
        FETCH_RECEIPTS_LIST_FAILURE
      ],
      // endpoint: `/receipts?from_date=2019-07-26&to_date=2019-10-24&limit=20&offset=0&sort=DESC`,
      endpoint: `/receipts?from_date=${fromDate}&to_date=${toDate}&limit=${limit.value}&offset=${offset}&sort_by=${sort_by}&sort_order=${sort_order}&category=${category}&store_name=${store_name}`,
      options: {
        method: "GET"
      }
    }
  };
};
