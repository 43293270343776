export default {
  content: {
    zIndex: "1000",
    borderRadius: "10px",
    boxShadow: "0 3px 6px 0 rgba(0,0,0,.13), 0 6px 20px 0 rgba(0,0,0,.12)"
  },
  item: {
    fontSize: "12px",
    padding: 0,
    fontWeight: "bold"
  },
  label: {
    fontWeight: "bold",
    fontSize: "16px"
  }
};
