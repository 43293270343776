import React, { memo, useEffect } from "react";
import { fetchReceiptDetails } from "../../actions/receipts";
import { connect } from "react-redux";
import EmaarLoader from "../../components/EmaarLoader";
import { humanizeCurrency } from "../../utils";
import ReceiptViewer from "./ReceiptViewer";
import { isEmpty } from "lodash";
import Moment from "moment";
import "../../styles/Receipts.scss";
import ReceiptItem from "./ReceiptItem";
import { loaderStyles } from "./utils";
// ====================================================
const ReceiptDetails = ({
  dispatch,
  match: {
    params: { receiptId, clientId }
  },
  receiptDetails: { data, isDataAvailable, isLoading },
  history: { push }
}) => {
  // ==================================================
  useEffect(() => {
    dispatch(fetchReceiptDetails(receiptId, clientId));
  }, []);
  // ==================================================
  const goToReceipts = () => {
    push("/receipts");
  };
  // ==================================================

  return (
    <>
      {isLoading ? (
        <div style={loaderStyles}>
          <EmaarLoader height={80} width={80} />
        </div>
      ) : (
        isDataAvailable &&
        !isEmpty(data) && (
          <div className="receipt">
            <div className="receipt-headers">
              <h1>Receipt Details</h1>
            </div>
            <div className="receipt-container">
              <div className="receipt-visual receipt-data">
                <div className="bill-area">
                  <ReceiptViewer url={data.urls[0]} />
                </div>
              </div>
              <div className="receipt-content receipt-data">
                <div className="receipt-details">
                  <div className="heading">
                    <h5>Receipt Details</h5>
                  </div>
                  <div>
                    <ul className="list">
                      <li className="list-item">
                        <span className="title">Company Name</span>
                        <span className="value">{data.store}</span>
                      </li>

                      <li className="list-item">
                        <span className="title">Invoice Total</span>
                        <span className="value">
                          {humanizeCurrency({
                            amount: data.total_amount,
                            type: "currency"
                          })}
                        </span>
                      </li>
                      <li className="list-item">
                        <span className="title">Invoice Date</span>
                        <span className="value">
                          {Moment(data.date).format("YYYY-MM-DD")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="line-items">
                  <div className="heading">
                    <h5>Line Items</h5>
                  </div>
                  <ul className="list">
                    {data.items.length
                      ? data.items.map(item => <ReceiptItem item={item} />)
                      : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="receipt-footer">
              <div className="receipt-footer__content">
                <div className="left" onClick={goToReceipts}>
                  <span className="direction-icons">
                    <i className="fas fa-angle-left"></i>
                  </span>
                  <span className="text start">List View</span>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
// ===========================================================
const mapStateToProps = state => {
  const {
    receipts: { receiptDetails }
  } = state;

  return { receiptDetails };
};
// ===========================================================
export default memo(connect(mapStateToProps)(ReceiptDetails));
