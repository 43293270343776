import React from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { connect } from "react-redux";
import "./style.scss";

// ==============================================================
const MerchantCategoriesTags = ({ categories }) => {
  const tags =
    categories &&
    categories.length > 0 &&
    categories.split(",").map(tag => ({ id: tag, text: tag }));
  return (
    <div>
      <ReactTags tags={tags && tags.length ? tags : []} readOnly />
    </div>
  );
};
// ==============================================================
const mapStateToProps = state => {
  const {
    dashboard: { receiptsBreakdown }
  } = state;
  return { receiptsBreakdown };
};
// ==============================================================
export default connect(mapStateToProps)(MerchantCategoriesTags);
