import moment from "moment";
import { humanizeCurrency } from "../../../utils";
// =================================================================

const calculateDateDiffence = (fromDate, toDate) => {
  const fDate = new Date(fromDate);
  const tDate = new Date(toDate);
  const diff = (tDate - fDate) / 3600000 / 24;
  return diff;
};
// =================================================================

const getMatchingsSalesFigures = (matchings, props) => {
  const {
    filters: { fromDate, toDate },
    dashboard: { salesFigures }
  } = props;
  const diff = calculateDateDiffence(fromDate, toDate);
  if (Object.keys(salesFigures.data).length > 0) {
    let { current, previous } = salesFigures.data;
    previous = previous || [];
    current = current || [];
    let longerArray = [];
    let shorterArray = [];
    if (current.length > previous.length) {
      longerArray = ["current", ...current];
      shorterArray = ["previous", ...previous];
    } else {
      longerArray = ["previous", ...previous];
      shorterArray = ["current", ...current];
    }
    for (let i = 1; i < longerArray.length; i++) {
      let matched = false;
      for (let j = 1; j < shorterArray.length; j++) {
        if (longerArray[i] && shorterArray[j]) {
          if (
            diff + 1 ===
            Math.abs(
              calculateDateDiffence(
                shorterArray[j]["day"],
                longerArray[i]["day"]
              )
            )
          ) {
            matched = true;
            matchings.push({
              current:
                longerArray[0] === "current" ? longerArray[i] : shorterArray[j],
              previous:
                longerArray[0] === "previous" ? longerArray[i] : shorterArray[j]
            });
          }
        }
      }
      if (!matched) {
        matchings.push({
          current:
            longerArray[0] === "current"
              ? longerArray[i]
              : {
                  day: moment(longerArray[i]["day"])
                    .add(diff + 1, "days")
                    .format("YYYY-MM-DD"),
                  sales_figure: 0
                },
          previous:
            longerArray[0] === "previous"
              ? longerArray[i]
              : {
                  day: moment(longerArray[i]["day"])
                    .subtract(diff + 1, "days")
                    .format("YYYY-MM-DD"),
                  sales_figure: 0
                }
        });
      }
    }
  }
};
// =================================================================

export const getSalesFiguresData = props => {
  let salesFiguresData = [
    {
      columns: ["", "Date", "", "Sales Figures"],
      data: []
    }
  ];

  let matchings = [];
  props && getMatchingsSalesFigures(matchings, props);
  matchings.length &&
    matchings.forEach(matching => {
      salesFiguresData.push({
        columns: ["", "", ""],
        data: [
          [
            "Current",
            matching.current["day"],
            "",
            humanizeCurrency({
              amount: matching.current["sales_figure"],
              type: "number"
            })
          ],
          [
            "Previous",
            matching.previous["day"],
            "",
            humanizeCurrency({
              amount: matching.previous["sales_figure"],
              type: "number"
            })
          ]
        ]
      });
    });

  return salesFiguresData;
};
