// =======================================================================
export const makeMerchantQueryString = (merchantListFilters, filters) => {
  // limit, offset, fromDate, toDate, sort, category
  const {
    limit,
    offset,
    store_name = "",
    merchantCategories = [],
    sort_by = "revenue",
    sort_order = "DESC"
  } = merchantListFilters;
  const { toDate, fromDate, selectedCategories } = filters;
  let category = merchantCategories[0] || "";
  if (merchantCategories.length > 1) {
    category = "";
  }
  return `limit=${
    limit.value
  }&offset=${offset}&fromDate=${fromDate}&toDate=${toDate}&category=${category}&store_name=${store_name}&sort_by=${sort_by}&sort_order=${sort_order}&selectedCategories=${selectedCategories.join(
    ","
  )}`;
};

// =======================================================================
export const parseMerchantQueryString = qs => {
  const str = qs.substring(1, qs.length);
  const paramArray = str.split("&");
  const r = {};

  paramArray.forEach(param => {
    const [key, value] = param.split("=");
    if (key === "limit") {
      r[key] = {
        label: value,
        value
      };
    } else if (key === "category") {
      r["merchantCategories"] = [value];
    } else if (key === "selectedCategories") {
      r[key] = value.split(",");
    } else {
      r[key] = value;
    }
  });

  return r;
};

// =======================================================================
export const makeMerchantDetailsQueryString = filters => {
  const { fromDate, toDate } = filters;
  return `fromDate=${fromDate}&toDate=${toDate}`;
};

// =======================================================================
export const parseMerchantDetailsQueryString = qs => {
  const str = qs.substring(1, qs.length);
  const paramArray = str.split("&");
  const r = {};
  paramArray.forEach(param => {
    const [key, value] = param.split("=");
    if (key === "limit") {
      r[key] = { label: value, value };
    } else {
      r[key] = value;
    }
  });
  return r;
};
// =======================================================================
